
import React, { FunctionComponent, useState, useEffect } from "react";

interface MiniPlansProps {
  nb: number;
};

const MiniPlansComponent: FunctionComponent<MiniPlansProps> = ( { nb } ) => {
  const [valueComponent, setValueComponent] = useState(0);

  useEffect(() => {
    setValueComponent(nb);
  }, [nb]);

  return (
    <div className="mini-plans-component card-panel grey lighten-5" >
      <p className="mini-plans-title" >plans d'actions en cours</p>
      <p className="mini-plans-value" >{valueComponent}</p>
    </div>
  )
};

export default MiniPlansComponent;
