
import React, { FunctionComponent, useState, useEffect } from "react";

import './onglet.css';
import { MENU_ONGLET_BUTTON_CONFIG, MENU_ONGLET_BUTTON_PLAN, MENU_ONGLET_BUTTON_SEND, MENU_ONGLET_BUTTON_SIGNATURE, MENU_ONGLET_HIDDEN } from "../../models/consts/ctrlProp";
import { VALIDATION_VIEW_SELECTED } from "../../models/validations";

interface MenuOngletComponentProps {
  view: string,
  onViewSelected: (v: string) => void;
};

const MenuOngletComponent: FunctionComponent<MenuOngletComponentProps> = ( { view, onViewSelected } ) => {
  const [viewSelected, setViewSelected] = useState(MENU_ONGLET_BUTTON_CONFIG);

  useEffect(() => {
    if( VALIDATION_VIEW_SELECTED.includes(view) ) {
      setViewSelected(view);
    }
  }, [view]);

  /* HANDELS */
  const handelSelectConfig = () => {
    if( viewSelected === MENU_ONGLET_BUTTON_CONFIG ) {
      setViewSelected(MENU_ONGLET_HIDDEN);
      onViewSelected(MENU_ONGLET_HIDDEN);
    } else {
      setViewSelected(MENU_ONGLET_BUTTON_CONFIG);
      onViewSelected(MENU_ONGLET_BUTTON_CONFIG);
    }
  };
  const handelSelectSend = () => {
    if( viewSelected === MENU_ONGLET_BUTTON_SEND ) {
      setViewSelected(MENU_ONGLET_HIDDEN);
      onViewSelected(MENU_ONGLET_HIDDEN);
    } else {
      setViewSelected(MENU_ONGLET_BUTTON_SEND);
      onViewSelected(MENU_ONGLET_BUTTON_SEND);
    }
  };
  const handelSelectPlan = () => {
    if( viewSelected === MENU_ONGLET_BUTTON_PLAN ) {
      setViewSelected(MENU_ONGLET_HIDDEN);
      onViewSelected(MENU_ONGLET_HIDDEN);
    } else {
      setViewSelected(MENU_ONGLET_BUTTON_PLAN);
      onViewSelected(MENU_ONGLET_BUTTON_PLAN);
    }
  };
  const handelSelectSignature = () => {
    if( viewSelected === MENU_ONGLET_BUTTON_SIGNATURE ) {
      setViewSelected(MENU_ONGLET_HIDDEN);
      onViewSelected(MENU_ONGLET_HIDDEN);
    } else {
      setViewSelected(MENU_ONGLET_BUTTON_SIGNATURE);
      onViewSelected(MENU_ONGLET_BUTTON_SIGNATURE);
    }
  }

  return (
    <div className="menu-onglet-component">
      <div className="menu-onglet-content">
        <button
          className="btn btn-vertical"
          style={ ( viewSelected === MENU_ONGLET_BUTTON_CONFIG ) ? { opacity: '1' } : { opacity: '0.5' } }
          key={ MENU_ONGLET_BUTTON_CONFIG }
          onClick={ handelSelectConfig }
        >Configuration</button>
        <button
          className="btn btn-vertical"
          style={ ( viewSelected === MENU_ONGLET_BUTTON_SEND ) ? { opacity: '1' } : { opacity: '0.5' } }
          key={MENU_ONGLET_BUTTON_SEND}
          onClick={ handelSelectSend }
        >Envoyer</button>
        <button
          className="btn btn-vertical"
          style={ ( viewSelected === MENU_ONGLET_BUTTON_PLAN ) ? { opacity: '1' } : { opacity: '0.5' } }
          key={MENU_ONGLET_BUTTON_PLAN}
          onClick={ handelSelectPlan }
        >Plan d'actions</button>
        <button
          className="btn btn-vertical"
          style={ ( viewSelected === MENU_ONGLET_BUTTON_SIGNATURE ) ? { opacity: '1' } : { opacity: '0.5' } }
          key={MENU_ONGLET_BUTTON_SIGNATURE}
          onClick={ handelSelectSignature }
        >Signer</button>
      </div>
    </div>
  )
}

export default MenuOngletComponent;
