
import { AgenceType, convertToAgenceType, initialAgence, ReceveidAgenceType } from "./agence";
import { convertToGroupememtType, GroupementType, initialGroupement, ReceveidGroupementType } from "./groupement";

// receveids dats
export type ReceveidResidenceType = {
  id: number,
  txt: string,
  agc: ReceveidAgenceType,
  grp: ReceveidGroupementType,
};

// typages
export type ResidenceType = {
  id: number,
  txt: string,
  agence: AgenceType,
  groupement: GroupementType,
};

// initilisation
export const initialResidence: ResidenceType = {
  id: 0,
  txt: '',
  agence: initialAgence,
  groupement: initialGroupement,
};

// functions
export const convertToResidenceType = (data: ReceveidResidenceType): ResidenceType => {
  return {
    id: data.id,
    txt: data.txt,
    agence: convertToAgenceType(data.agc),
    groupement: convertToGroupememtType(data.grp),
  };
};
