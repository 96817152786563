
export const ID_RESIDENCE_TYPE_REFERENT_ENCADRANT = 'encadrant';
export const ID_RESIDENCE_TYPE_REFERENT_ADMINISTRATIF = 'admin';
export const ID_RESIDENCE_TYPE_REFERENT_ENTRETIEN = 'entretien';

export const ID_RESIDENCE_TYPE_SELECT_ENTRETIEN_PRESTA = 'p';
export const ID_RESIDENCE_TYPE_SELECT_ENTRETIEN_AGENT = 'a';

export const ZONE_TYPE_PROXI = 'p';
export const ZONE_TYPE_CONTRAT = 'c';
