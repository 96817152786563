
import React, { FunctionComponent, useState, useEffect } from "react";
import { CriterGrillePropType, ElementGrillePropType, ZoneGrillePropType } from "../../../models/ctrlProp";
import NoteComponent from "../notes";
import { ConfigSocPropType } from "../../../models/configSocProp";
import ElementPropViewComponent from "./element";
import { NOTE_VIEW_GRILLE } from "../../../models/consts/ctrlProp";
import { MbrType } from "../../../models/mbr";

interface ZonePropViewComponentProps {
  mbr: MbrType,
  zone: ZoneGrillePropType;
  conf: ConfigSocPropType;
  zonesToShow: string | null;
  zoneSelected: number;
  isModifiable: boolean;
  onZoneChanged: ( z: number, e: ElementGrillePropType[] ) => void;
  onZoneClicked: () => void;
  onZoneSelected: ( i: number ) => void;
};

const ZonePropViewComponent: FunctionComponent<ZonePropViewComponentProps> = ( { mbr, zone, conf, zonesToShow, zoneSelected, isModifiable, onZoneChanged, onZoneClicked, onZoneSelected } ) => {
  const [listElements, setListeElements] = useState<ElementGrillePropType[]>([]);
  const [noteZone, setNoteZone] = useState<number | null>(null);
  const [elementSelected, setElementSelected] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isMonted, setIsMonted] = useState(false);
  const [isStarted, setIsStarted] = useState(false);

  useEffect(() => {
    if( zoneSelected === zone.id ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [zoneSelected]);
  useEffect(() => {
    if( isMonted && isStarted ) {
      onZoneChanged(zone.id, listElements);
    }
  }, [listElements]);
  useEffect(() => {
    if( isMonted && isStarted && ( zone.note ) ) {
      setNoteZone(zone.note);
    }
  }, [zone]);

  useEffect(() => {
    if( zone.id > 0 ) {
      setListeElements(zone.elements);
    }
  }, []);

  const handelOpenZone = () => {
    const b = !isOpen;

    setIsOpen(b);
    onZoneSelected(zone.id);
    onZoneClicked();
    setIsMonted(true);
  }
  const handelElementChanged = ( e: number, c: CriterGrillePropType[] ) => {
    setIsStarted(true);

    const newElements = listElements.map(element => {
      return {
        ...element,
        criters: ( element.id === e ) ? c : element.criters,
      };
    });

    setListeElements(newElements);
    onZoneClicked();
  };

  const selectElement = ( i: number ) => {
    setElementSelected(i);
  }

  return (
    <div className="zone-prop-view-component">
      <div className="zone-prop-view-content" style={ { display: ( ( zonesToShow === null ) || ( zonesToShow === zone.type ) ) ? "block" : "none" } }>
        <div className="title-zone-container" onClick={ handelOpenZone }>
          <NoteComponent
            lbl={ ( zone.txt ) ? zone.txt : '' }
            val={ ( zone.note === undefined ) ? null : zone.note }
            conf={ conf }
            parent={ NOTE_VIEW_GRILLE }
          />
        </div>

        <div className="list-elements-container" style={ { display: ( isOpen ) ? "block" : "none" } }>
          { ( Object.values(listElements).length > 0 ) ? (
            <div className="list-elements">
              { Object.values(zone.elements).map(( element ) => {
                return (
                  <ElementPropViewComponent
                    mbr={ mbr }
                    key={ element.id }
                    element={ element }
                    conf={ conf }
                    isModifiable={ isModifiable }
                    elementSelected={ elementSelected }
                    onElementChange={ handelElementChanged }
                    onElementClicked={ onZoneClicked }
                    onElementSelected={ selectElement }
                  />
                )
              })}
            </div>
          ) : (
            <div>Aucun élément à afficher</div>
          ) }
        </div>
      </div>
    </div>
  )
}

export default ZonePropViewComponent;
