
import React, { FunctionComponent, useState, useEffect } from "react";

import "./statistiques.css";
import menuIcone from '../../assets/menu.png';

import { STAT_ENCART_TYPE_AGENCE, STAT_ENCART_TYPE_CONTROLEUR, STAT_ENCART_TYPE_GROUPEMENT, STAT_ENCART_TYPE_RESIDENCE } from "../../models/consts/statistiques";
import { MbrType } from "../../models/mbr";
import { convertToPlanActionsType, convertToStatType, initialPlanActions, initialStatistiques, PlanActionsStatType, StatType } from "../../models/statistiques";

import { fetchGetStatAgence, fetchGetStatControleur, fetchGetStatGroupement, fetchGetStatPlanActions, fetchGetStatResidence } from "../../services/getStatistiques";
import MiniStat from "./mini";
import MiniPlansComponent from "./miniPlans";
import { Menu } from "../menu/menu";
import FullStat from "./full";
import FullPlanComponent from "./fullPlans";

interface StatEncartProps {
  mbr: MbrType,
  agc: number,
  grp: number,
  rsd: number,
  showFull: boolean,
  onMenuOpen: () => void;
}

const StatComponent: FunctionComponent<StatEncartProps> = ( { mbr, agc, grp, rsd, showFull, onMenuOpen } ) => {
  const [dataCtrl, setDataCtrl] = useState<StatType>({...initialStatistiques, type: STAT_ENCART_TYPE_CONTROLEUR});
  const [dataPlans, setDataPlans] = useState<PlanActionsStatType>(initialPlanActions);
  const [dataAgc, setDataAgc] = useState<StatType>({...initialStatistiques, type: STAT_ENCART_TYPE_AGENCE});
  const [dataGrp, setDataGrp] = useState<StatType>({...initialStatistiques, type: STAT_ENCART_TYPE_GROUPEMENT});
  const [dataRsd, setDataRsd] = useState<StatType>({...initialStatistiques, type: STAT_ENCART_TYPE_RESIDENCE});
  const [showMenu, setShowMenu] = useState(false);
  const [showFullComponent, setShowFullComponent] = useState(true);

  useEffect(() => {
    const fetchControleur = async () => {
      try {
        const response = await fetchGetStatControleur(mbr.id, mbr.mac);

        if( response.result ) {
          const newStat = convertToStatType(response.data);

          setDataCtrl(prevData => ({
            ...prevData,
            ...newStat,
          }));
        } else {
          console.error(adrFile+'fetchControleur::response::ERROR', response.data.txt);
        }
      } catch(error) {
        console.error(adrFile+'fetchControleur::ERROR', error);
      }      
    };
    const fetchPlansActions = async () => {
      try {
        const param = STAT_ENCART_TYPE_CONTROLEUR + ':' + mbr.id;
        const response = await fetchGetStatPlanActions(mbr.id, mbr.mac, param);

        if( response.result ) {
          var newPlans = convertToPlanActionsType(response.data);

          setDataPlans(prevData => ({
            ...prevData,
            ...newPlans,
          }));
        } else {
          console.error(adrFile+'fetchPlansActions::response::ERROR', response.data.txt);
        }
      } catch(error) {
        console.error(adrFile+'fetchPlansActions::ERROR', error);
      }
    };

    if( mbr.id > 0 ) {
      fetchControleur();
      fetchPlansActions();
    }
  }, [mbr]);
  useEffect(() => {
    const fetchAgence = async () => {
      try {
        const response = await fetchGetStatAgence(mbr.id, mbr.mac, agc);

        if( response.result ) {
          const newStat = convertToStatType(response.data);

          setDataAgc(prevData => ({
            ...prevData,
            ...newStat,
          }));
        } else {
          console.error(adrFile+'fetchAgence::response::ERROR', response.data.txt);
        }
      } catch(error) {
        console.error(adrFile+'fetchAgence::ERROR', error);
      }
    };
    const fetchPlansActions = async () => {
      try {
        const param = STAT_ENCART_TYPE_AGENCE + ':' + agc;
        const response = await fetchGetStatPlanActions(mbr.id, mbr.mac, param);

        if( response.result ) {
          const newPlans = convertToPlanActionsType(response.data);

          setDataPlans(prevData => ({
            ...prevData,
            ...newPlans,
          }));
        } else {
          console.error(adrFile+'fetchPlansActionsAgc::response::ERROR', response.data.txt);
        }
      } catch(error) {
        console.error(adrFile+'fetchPlansActionsAgc::ERROR', error);
      }
    };

    if( ( mbr.id > 0 ) && ( agc > 0 ) ) {
      fetchAgence();
    }
  }, [agc]);
  useEffect(() => {
    const fetchGroupement = async () => {
      try {
        const response = await fetchGetStatGroupement(mbr.id, mbr.mac, agc, grp);

        if( response.result ) {
          const newStat = convertToStatType(response.data);

          setDataGrp(prevData => ({
            ...prevData,
            ...newStat,
          }));
        } else {
          console.error(adrFile+'fetchGroupement::response::ERROR', response.data.txt);
        }
      } catch(error) {
        console.error(adrFile+'fetchGroupement::ERROR', error);
      }
    };
    const fetchPlansActions = async () => {
      try {
        const param = STAT_ENCART_TYPE_GROUPEMENT + ':' + grp;
        const response = await fetchGetStatPlanActions(mbr.id, mbr.mac, param);

        if( response.result ) {
          const newPlans = convertToPlanActionsType(response.data);

          setDataPlans(prevData => ({
            ...prevData,
            ...newPlans,
          }));
        } else {
          console.error(adrFile+'fetchPlansActionsGrp::response::ERROR', response.data.txt);
        }
      } catch(error) {
        console.error(adrFile+'fetchPlansActionsGrp::ERROR', error);
      }
    };

    if( ( mbr.id > 0 ) && ( grp > 0 ) ) {
      fetchGroupement();
    } else {
      setDataGrp(prevState => ({
        ...prevState,
        key: 0,
      }));
    }
  }, [grp]);
  useEffect(() => {
    const fetchResidence = async () => {
      try {
        const response = await fetchGetStatResidence(mbr.id, mbr.mac, agc, grp, rsd);

        if( response.result ) {
          const newStat = convertToStatType(response.data);

          setDataRsd(prevData => ({
            ...prevData,
            ...newStat,
          }));
        } else {
          console.error(adrFile+'fetchResidence::response::ERROR', response.data.txt);
        }
      } catch(error) {
        console.error(adrFile+'fetchResidence::ERROR', error);
      }
    };
    const fetchPlansActions = async () => {
      try {
        const param = STAT_ENCART_TYPE_RESIDENCE + ':' + rsd;
        const response = await fetchGetStatPlanActions(mbr.id, mbr.mac, param);

        if( response.result ) {
          const newPlans = convertToPlanActionsType(response.data);

          setDataPlans(prevData => ({
            ...prevData,
            ...newPlans,
          }));
        } else {
          console.error(adrFile+'fetchPlansActionsRsd::response::ERROR', response.data.txt);
        }
      } catch(error) {
        console.error(adrFile+'fetchPlansActionsRsd::ERROR', error);
      }
    };

    if( ( mbr.id > 0 ) && ( rsd > 0 ) ) {
      fetchResidence();
    } else {
      setDataRsd(prevState => ({
        ...prevState,
        key: 0,
      }));
    }
  }, [rsd]);
  useEffect(() => {
    setShowFullComponent(showFull);
  }, [showFull]);

  const toggleMenu = () => {
    if( !showMenu ) {
      onMenuOpen()
    }
    
    setShowMenu(!showMenu);
  };
  
  return (
    <div className="stat-screen">
      <div className="mini-stat-container">
        <MiniStat data={ dataCtrl }/>
        <MiniPlansComponent nb={ dataPlans.nb }/>
        <MiniStat data={ dataAgc }/>
        <MiniStat data={ dataGrp }/>
        <MiniStat data={ dataRsd }/>
        <img src={ menuIcone } alt="Menu" className="btn-open-menu" onClick={ toggleMenu }/>
        { ( mbr.id > 0 ) && <Menu mbr={ mbr } show={ showMenu }/> }
      </div>

      { ( showFullComponent ) &&
        <div className="full-stat-container">
          <FullStat data={ dataCtrl }/>
          <FullPlanComponent data={ dataPlans.data }/>
          <FullStat data={ dataAgc }/>
          <FullStat data={ dataGrp }/>
        </div>
      }
    </div>
  );
};

export default StatComponent;
