
import CryptoJS from 'crypto-js';

import { SECRET_KEY } from "../models/consts";
import { STORE_PARAM_MEMBRE } from "../models/consts/store";

import { initialMbr } from "../models/mbr";

const makeMbr = async ( debugg?: boolean ) => {
  const encryptedData = localStorage.getItem(STORE_PARAM_MEMBRE);

  var dataMbr = initialMbr;

  if( encryptedData !== null ) {
    const decryptedData = JSON.parse(CryptoJS.AES.decrypt(encryptedData, SECRET_KEY).toString(CryptoJS.enc.Utf8));

    dataMbr.id = decryptedData.id;
    dataMbr.pseudo = decryptedData.pseudo;
    dataMbr.mdp = decryptedData.mdp;
    dataMbr.mac = decryptedData.mac;
    dataMbr.idAgent = decryptedData.idAgent;
    dataMbr.txt = decryptedData.txt;
    dataMbr.mail = decryptedData.mail;
    dataMbr.niveau = decryptedData.niveau;
    dataMbr.tel = decryptedData.tel;
    dataMbr.abonnements = decryptedData.abonnements;
  };

  return dataMbr;
};

export default makeMbr;
