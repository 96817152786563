
import axios from "axios";
import { API_URL_SET_LOGIN } from "../models/consts/apiUrls";

export default async function fetchLogin( p: string, m: string, n: string | null, t: string ) {
  try {
    const url = API_URL_SET_LOGIN;
    const params = {
      pseudo: p,
      mdp: m,
      mac: n,
      type: t,
    };
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    return response.data;
  } catch( error ) {
    console.error(adrFile+'ERROR', error);

    return {
      result: false,
      donn: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion:',
      },
    }
  }
}
