
import { AgenceType, convertToAgenceType, initialAgence, ReceveidAgenceType } from "./agence";

// receveids datas
export type ReceveidGroupementType = {
  id: number,
  txt: string,
  agc: ReceveidAgenceType,
};

// typages
export type GroupementType = {
  id: number,
  txt: string,
  agence: AgenceType,
};

// initilisations
export const initialGroupement : GroupementType = {
  id: 0,
  txt: '',
  agence: initialAgence,
};

// functions
export const convertToGroupememtType = (data: ReceveidGroupementType): GroupementType => {
  return {
    id: data.id,
    txt: data.txt,
    agence: convertToAgenceType(data.agc),
  };
};
