
import React, { FunctionComponent, useState, useEffect } from "react";

import './index.css';

interface PlanActionsComponentProps {
  id: number;
  date: string;
  mess: string;
};

const PlanActionsComponent: FunctionComponent<PlanActionsComponentProps> = ( { id, date, mess } ) => {
  const [dateEcheance, setDateEcheance] = useState('');
  const [message, setMessage] = useState('');
  const [isModified, setIfModified] = useState(false);

  useEffect(() => {
    setDateEcheance(date);
  }, [date]);
  useEffect(() => {
    setMessage(mess);
  }, [mess]);

  const handelEncheancePlan = ( v: string ) => {
    setDateEcheance(v);
    setIfModified(true);
  };
  const handelMessagePlan = ( v: string ) => {
    setMessage(v);
    setIfModified(true);
  };
  const handelLevePlan = () => { /* A FAIRE */
    
  };
  const handelNewPlan = () => { /* A FAIRE */
    
  };
  const handelSavePlan = () => { /* A FAIRE */
    
  };

  /* FUNCTIONS */

  return (
    <div className="plan-actions-component">
      <div className="plan-actions-content">
        <div className="echeance-container">
          <label>Échéance</label>
          <input type="date" value={ dateEcheance } onChange={ ( e ) => handelEncheancePlan(e.target.value) }/>
        </div>

        <div className="message-container">
          <textarea value={ message } onChange={ ( e ) => handelMessagePlan(e.target.value) }/>
        </div>

        <div className="buttons-container">
          <button
            className="btn btn-std"
            style={ { opacity: ( id > 0 ) ? "1" : "0.5" } }
            key="leve"
            onClick={ handelLevePlan }
          >Lever</button>

          <button
            className="btn btn-std"
            style={ { opacity: ( id > 0 ) ? "1" : "0.5" } }
            key="modifier"
            onClick={ handelNewPlan }
          >Modifier</button>

          <button
           className="btn btn-std"
           style={ { opacity: ( isModified ) ? "1" : "0.5" } }
           key="save"
           onClick={ handelSavePlan }
          >Enregistrer</button>
        </div>
      </div>
    </div>
  )
}

export default PlanActionsComponent;
