
import React , { FunctionComponent, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";

import { NOTIFY_VARIANT_ERROR, NOTIFY_VARIANT_SUCCESS, useNotification } from "../notify";

import { fetchUploadImg } from "../../services/uploadImg";
import { MbrType } from "../../models/mbr";
import { convertToImgCommentPropType, ImgCommentPropType } from "../../models/ctrlProp";

interface CameraComponentProps {
  mbr: MbrType;
  img?: ImgCommentPropType;
  onCapture: ( imageSrc: ImgCommentPropType | null ) => void;
};

const CameraComponent: FunctionComponent<CameraComponentProps> = ( { mbr, img, onCapture } ) => {
  const adrFile = '/components/camera/index.tsx::CameraComponent::';
  const webcamRef = useRef<Webcam>(null);

  const notify = useNotification();

  const [captureComponent, setCaptureComponent] = useState<ImgCommentPropType | null>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [showCapture, setShowCapture] = useState(true);
  const [showPicture, setShowPicture] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    if( img !== undefined ) {
      setCaptureComponent(img);

      if( img.id > 0 ) {
        setShowCapture(false);
        setShowPicture(false);
        setShowPreview(true);
      } else {
        setShowPicture(false);
        setShowPreview(false);
        setShowCapture(true);
      }
    }
  }, [img]);

  const capture = () => {
    if( webcamRef.current ) {
      const image = webcamRef.current.getScreenshot();

      setImageSrc(image);

      setShowCapture(false);
      setShowPreview(false);
      setShowPicture(true);
    }
  };
  const clearCapture = () => {
    setImageSrc(null);

    setShowPicture(false);
    setShowPreview(false);
    setShowCapture(true);
  };
  const delCapture = () => {
    setCaptureComponent(null);

    setShowPicture(false);
    setShowPreview(false);
    setShowCapture(true);
  };
  const sendImageToServer = async () => {
    if( imageSrc ) {
      try {
        const response = await fetchUploadImg(mbr.id, mbr.mac, imageSrc);

        if( response.result ) {
          const newImg = convertToImgCommentPropType(response.data);

          setCaptureComponent(newImg);

          setShowCapture(false);
          setShowPicture(false);
          setShowPreview(true);

          onCapture(newImg);
          
          notify("Enregistrement de l'image réussie", { variant: NOTIFY_VARIANT_SUCCESS });
        } else {
          notify(response.data.txt, { variant: NOTIFY_VARIANT_ERROR });
          console.error(adrFile+"Erreur lors de l'enregistrement de l'image :", response.debugg);
        }
      } catch( error ) {
        console.error(adrFile+"Erreur lors de l'envoi de l'image :", error);
      }
    }
  }

  return (
    <div className="webcam-container">
      { ( showCapture && !showPicture && !showPreview ) &&
        <div className="capture-container">
          <Webcam
            className="web-cam-component"
            audio={ false }
            ref={ webcamRef }
            screenshotFormat="image/png"
            width={ 300 }
          />
          <br/>
          <button className="btn-take-picture btn btn-std" onClick={ capture }>CAPTURER</button>
        </div>
      }

      { ( !showCapture && showPicture && !showPreview && ( imageSrc !== null ) ) &&
        <div className="view-capture-container">
          <img src={ imageSrc } alt="Captured"/>
          <div className="btn-valid-capture-container">
            <button className="btn-clear-capture btn btn-std" onClick={ clearCapture }>ANNULER</button>
            <button className="btn-save-capture btn btn-std" onClick={ sendImageToServer }>ENREGISTRER</button>
          </div>
        </div>
      }
      { !showCapture && !showPicture && showPreview && ( captureComponent !== null ) && ( captureComponent.id > 0 )  &&
        <div className="view-capture-container">
          <img src={ captureComponent.txt } alt="Captured" width={ 300 }/>
          <div className="btn-suppr-capture-container">
            <button className="btn-suppr-capture btn btn-std" onClick={ delCapture }>SUPPRIMER</button>
          </div>
        </div>
      }
    </div>
  )
}

export default CameraComponent;
