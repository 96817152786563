
type ReceveidAutoSend = {
  val: number,
  dest: string,
};
type ReceveidLimtesProp = {
  basse: number,
  haute: number,
};
type ReceveidConfigSocProp = {
  limites: ReceveidLimtesProp,
  auto_send: ReceveidAutoSend,
};

type AutoSendType = {
  val: number,
  destinataire: string,
};
type LimitesPropType = {
  basse: number,
  haute: number,
};
export type ConfigSocPropType = {
  limites: LimitesPropType,
  autoSend: AutoSendType,
};

const initialAutoSend: AutoSendType = {
  val: 101,
  destinataire: '',
};
const initialLimites: LimitesPropType = {
  basse: 45,
  haute: 70,
};
export const initialConfigSocProp: ConfigSocPropType = {
  limites: { ...initialLimites },
  autoSend: {  ...initialAutoSend },
};

const convertToAutoSendType = ( data: ReceveidAutoSend, debugg?: boolean ): AutoSendType => {
  const adrFile = '/models/configSocProp.ts::convertToAutoSendType::';
  const debuggComponent = ( ( debugg !== null ) && ( debugg !== undefined ) ) ? debugg : false;

  if( debuggComponent ) {
    console.log(adrFile+'data', data);
  }

  return {
    val: data.val,
    destinataire: data.dest,
  };
};
const convertToLimitesPropType = ( data: ReceveidLimtesProp, debugg?: boolean ): LimitesPropType => {
  const adrFile = '/models/configSocProp.ts::convertToLimitesPropType::';
  const debuggComponent = ( ( debugg !== null ) && ( debugg !== undefined ) ) ? debugg : false;

  if( debuggComponent ) {
    console.log(adrFile+'data', data);
  }

  return {
    basse: data.basse,
    haute: data.haute,
  };
};
export const convertToConfigSocPropType = ( data: ReceveidConfigSocProp, debugg?: boolean ): ConfigSocPropType => {
  const adrFile = '/models/configSocProp.ts::convertToConfigSocPropType::';
  const debuggComponent = ( ( debugg !== null ) && ( debugg !== undefined ) ) ? debugg : false;

  if( debuggComponent ) {
    console.log(adrFile+'data', data);
  }

  return {
    limites: convertToLimitesPropType(data.limites, debuggComponent),
    autoSend: convertToAutoSendType(data.auto_send, debuggComponent),
  };
};
