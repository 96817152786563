
import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react";

import { CommentCriterGrillePropType, CriterGrillePropType, ImgCommentPropType } from "../../../models/ctrlProp";
import ModalDialsComponent from "../../dials";
import { DIALOG_TYPE_COMMENT } from "../../dials/const";
import { MbrType } from "../../../models/mbr";

interface CriterPropViewComponentProps {
  mbr: MbrType,
  criter: CriterGrillePropType;
  isModifiable: boolean;
  onNoteChange: ( i: number, v: number | null, c: CommentCriterGrillePropType | null ) => void;
};

const CriterPropViewComponent = forwardRef(( { mbr, criter, isModifiable, onNoteChange }: CriterPropViewComponentProps, ref ) => {
  const [valueComponent, setValueComponent] = useState<null | number>(null);
  const [canModify, setIfCanModify] = useState(true);
  const [commentComponent, setCommentComponent] = useState<CommentCriterGrillePropType | null>(null);
  const [showCommentDial, setShowCommentDial] = useState(false);
  const [isMonted, setIsMonted] = useState(false);
  const [isStarted, setIsStarted] = useState(false);

  useEffect(() => { 
    if( isModifiable && isMonted && isStarted && ( criter.val !== undefined ) ) { 
      setValueComponent(criter.val);
    }
    if( isMonted ) {
      setIsStarted(true);
    }
  }, [criter]);
  useEffect(() => {
    setIfCanModify(isModifiable);
  }, [isModifiable]);
  useEffect(() => { 
    if( !isMonted && !isStarted ) {
      setIsMonted(true);
      setIsStarted(true);
    }
  }, [valueComponent, commentComponent]);

  useEffect(() => {
    if( criter.val !== undefined ) {
      setValueComponent(criter.val);
    }
  }, []);



  /* HANDELS */
  const handelSetSO = () => {
    if( canModify && ( valueComponent !== null ) ) {
      const newImg: ImgCommentPropType = {
        id: 0,
        txt: '',
      };
      const newComment: CommentCriterGrillePropType = {
        txt: '',
        img: newImg,
      };

      setIsStarted(true);
      setValueComponent(null);
      onNoteChange(criter.id, null, newComment);
    }
  };
  const handelSetBad = () => {
    if( canModify ) {
      setIsStarted(true);

      if( valueComponent !== 0 ) {
        setValueComponent(0);
        onNoteChange(criter.id, 0, commentComponent);
      } else {
        setShowCommentDial(true);
      }
    }
  };
  const handelSetOk = () => {
    if( canModify && ( valueComponent !== 1 ) ) {
      const newImg: ImgCommentPropType = {
        id: 0,
        txt: '',
      };
      const newComment: CommentCriterGrillePropType = {
        txt: '',
        img: newImg,
      };

      setIsStarted(true);
      setValueComponent(1);
      onNoteChange(criter.id, 1, newComment);
    }
  };

  const handelCloseCommentDial = () => {
    setShowCommentDial(false);
  };
  const handelSetNewComment = ( t: string, i: ImgCommentPropType ) => {
    const newImg: ImgCommentPropType = {
      id: i.id,
      txt: i.txt,
    };
    const newComment: CommentCriterGrillePropType = {
      txt: t,
      img: newImg,
    };

    setIsStarted(true);

    setCommentComponent(newComment);
    onNoteChange(criter.id, valueComponent, newComment);
    
    setShowCommentDial(false);
  };
  const handelSupprComment = () => {
    const newImg: ImgCommentPropType = {
      id: 0,
      txt: '',
    };
    const newComment: CommentCriterGrillePropType = {
      txt: '',
      img: newImg,
    };

    setIsStarted(true);

    setCommentComponent(newComment);
    onNoteChange(criter.id, valueComponent, newComment);

    setShowCommentDial(false);
  };



  /* FUNCTIONS */
  useImperativeHandle(ref, () => ({
    clickSetSO() {
      setIsStarted(true);
      handelSetSO();
    },
    clickSetBad() {
      setIsStarted(true);
      handelSetBad();
    },
    clickSetOk() {
      setIsStarted(true);
      handelSetSO();
    },
  }));


  return (
    <div className="criter-prop_view-component">
      <div className="criter-prop-view-content">
        <div className="line-criter-container row">
          <div className="lbl-criter-prop col s6 m6 l6">
            { ( criter.txt ) ? criter.txt : '' }
          </div>

          <button
            className="btn btn-std col s2 m2 l2"
            style={ { opacity: ( valueComponent === null ) ? "1" : "0.5" } }
            onClick={ handelSetSO }
          >S O</button>
          <button
            className="btn btn-std col s2 m2 l2"
            style={ { opacity: ( valueComponent === 0 ) ? "1" : "0.5" } }
            onClick={ handelSetBad }
          >O</button>
          <button
            className="btn btn-std col s2 m2 l2"
            style={ { opacity: ( valueComponent === 1 ) ? "1" : "0.5" } }
            onClick={ handelSetOk }
          >I</button>
        </div>
      </div>

      <ModalDialsComponent
        mbr={ mbr }
        dialogType={ DIALOG_TYPE_COMMENT }
        isOpen={ showCommentDial }
        txt={ ( criter.comment ) ? criter.comment.txt : '' }
        img={ ( criter.comment ) ? criter.comment.img : { id: 0, txt: '' } }
        onClose={ handelCloseCommentDial }
        onSetComment={ handelSetNewComment }
        onSupprComment={ handelSupprComment }
      />
    </div>
  )
});

export default CriterPropViewComponent;
