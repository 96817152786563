
import axios from "axios";
import { API_URL_SET_GRILLE_CTRLPROP, API_URL_SET_SIGNATURE } from "../models/consts/apiUrls";

export async function fetchSetCtrlProp( idMbr: number, mac: string, data: string ) {
  try {
    const url = API_URL_SET_GRILLE_CTRLPROP;
    const params = {
      mbr: idMbr,
      mac: mac,
      val: data,
    };
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });

    return response.data;
  } catch( error ) {
    console.error(adrFile+'ERROR', error);

    return {
      result: false,
      donn: {
        numero: 404,
        txt: 'setCtrlProp.tsx::fetchSetCtrlProp::Une erreur s\'est produite lors de la connexion:',
      },
    };
  }
};
export async function fetchSetSignature( idMbr: number, mac: string, ctrl: number, sig1: string, sig2?: string ) {
  try {
    const url = API_URL_SET_SIGNATURE;
    const params = {
      mbr: idMbr,
      mac: mac,
      val: ctrl,
      sig1: sig1,
      sig2: ( ( sig2 === null ) || ( sig2 === undefined ) ) ? null : sig2,
    };
    const reponse = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });

    return reponse.data;
  } catch( error ) {
    console.error(adrFile+'ERROR', error);

    return {
      result: false,
      donn: {
        numero: 404,
        txt: 'setCtrlProp.tsx::fetchSetSignature::Une erreur s\'est produite lors de la connexion:',
      },
    };
  }
};
