
import axios from "axios";
import { API_URL_GET_RECUP_MAIL } from "../models/consts/apiUrls";

export default async function fetchRecupMail( n: string ) {
  try {
    const url = API_URL_GET_RECUP_MAIL;
    const params = {
      mail: n,
    };
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    return response.data;
  } catch( error ) {
    console.error(adrFile+'ERROR', error);

    return {
      result: false,
      donn: {
        numero: 404,
        txt: 'Une erreur s\'est produite lors de la connexion:',
      },
    }
  }
}
