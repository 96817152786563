
import React, { FunctionComponent, useEffect, useState } from "react";

import { LinePlanActionsStatType } from "../../models/statistiques";

interface FullPlanComponentProps {
  data: LinePlanActionsStatType[];
};

const FullPlanComponent: FunctionComponent<FullPlanComponentProps> = ( { data } ) => {
  return (
    <div className="full-plans-component" >
      <table>
        <thead>
          <tr>
            <th>Résidence</th>
            <th>Date</th>
            <th>Échéance</th>
            <th>Contenu</th>
          </tr>
        </thead>
        <div className="table-scroll">
          <tbody>
            { data.map((donnee) => (
              <tr key={donnee.id} >
                <td>{donnee.rsd}</td>
                <td className="td-center">{donnee.date}</td>
                <td className="td-center">{donnee.echeance}</td>
                <td>{donnee.txt}</td>
              </tr> 
            ))}
          </tbody>
        </div>
      </table>
    </div>
  )
};

export default FullPlanComponent;
