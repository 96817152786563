
import React, { FunctionComponent, useState, useEffect } from "react";

import { MoisStatType, StatType } from "../../models/statistiques";

import MyComposedChart from "./graph";

interface FullStatProps {
  data: StatType;
};

const FullStat: FunctionComponent<FullStatProps> = ( { data } ) => {
  const [dataComponent, setDataComponent] = useState<MoisStatType[]>([]);
  const [isDefined, setIsDefined] = useState(false);

  useEffect(() => {
    setIsDefined(data.key > 0);
    setDataComponent(data.data);
  }, [data]);

  return (
    <div className="full-stat-component" >
      { ( isDefined ) &&
        <MyComposedChart data={dataComponent} />
      }
    </div>
  );
};

export default FullStat;
