
export const LOGIN_URL = '/';
export const RECUP_MAIL_URL = '/recup-mail';
export const SELECT_ENTRY_URL = '/select-entry';
export const CONTROL_PROP_URL = '/control-prop';
export const CONFIGURATION_URL = '/config';

export const LOGIN_ALLOWED_PATHS = [''];
export const RECUP_MAIL_ALLOWED_PATHS = ['/', RECUP_MAIL_URL];
export const CONTROL_PROP_ALLOWED_PATHS = ['/', CONTROL_PROP_URL];
