
import React, { FunctionComponent, useRef, useEffect, useState } from 'react';
import SignaturePad from 'signature_pad';

import './signature.css';

interface SignatureProps {
  numberOfSignatures: number;
  isSigned: boolean;
  onSignature: ( sigs: string[] ) => void;
};

const SignatureComponent: FunctionComponent<SignatureProps> = ({ numberOfSignatures, isSigned, onSignature }) => {
  const [canModify, setIfCanModify] = useState(true);

  const canvasRefs = useRef<(HTMLCanvasElement | null)[]>([]);
  const signaturePads = useRef<SignaturePad[]>([]);

  useEffect(() => {
    setIfCanModify(isSigned);
  }, [isSigned]);
  useEffect(() => {
    canvasRefs.current.forEach((canvas, index) => {
      if( canvas ) {
        signaturePads.current[index] = new SignaturePad(canvas);
      }
    });
  }, []);

  const clearSignature = (index: number) => {
    if( canModify ) {
      signaturePads.current[index].clear();
    }
  };

  const saveAllSignatures = () => { /* A FAIRE */
    if( canModify ) {
      const savedSignatures = signaturePads.current.map(( signaturePad, index ) => {
        const dataURL = signaturePad.toDataURL();

        return dataURL;
      });
      
      signaturePads.current.forEach(( signaturePad ) => {
        signaturePad.off();
      });

      onSignature(savedSignatures);
      setIfCanModify(false);
    }
  };

  return (
    <div>
      {[...Array(numberOfSignatures)].map((_, index) => (
        <div key={index}>
          <canvas ref={ (el) => (canvasRefs.current[index] = el) }/>
          <div>
            <button
              className='btn btn-std'
              disabled={ !canModify }
              onClick={ () => clearSignature(index) }>
                Vider
            </button>
          </div>
        </div>
      ))}

      <button 
        className='btn btn-std'
        style={ { marginTop: '30px' } }
        disabled={ !canModify }
        onClick={ saveAllSignatures }>Signer</button>
    </div>
  );
};

export default SignatureComponent;
