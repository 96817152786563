
import CryptoJS from 'crypto-js';

import { SECRET_KEY } from "../models/consts";
import { STORE_PARAM_CIBLE } from "../models/consts/store";

export const FetchSetParamCible = ( agc: number, grp: number, rsd: number, entry: number ) => {
  try{
    const dataCible = {
      agc: agc,
      grp: grp,
      rsd: rsd,
      entry: entry,
    };
    const jsonCible = JSON.stringify(dataCible);

    let encryptedData = CryptoJS.AES.encrypt(jsonCible, SECRET_KEY).toString();
    localStorage.setItem(STORE_PARAM_CIBLE, encryptedData);
  } catch( error ) {
    if( debuggBomponent ) {
      console.error(adrFile+'ERROR:', error);
    }
  }
}
