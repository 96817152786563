
import React, { FunctionComponent, useState, useEffect, useRef } from "react";
import { CommentCriterGrillePropType, CriterGrillePropType, ElementGrillePropType } from "../../../models/ctrlProp";
import { ConfigSocPropType } from "../../../models/configSocProp";
import NoteComponent from "../notes";
import CriterPropViewComponent from "./criter";
import { NOTE_VIEW_GRILLE } from "../../../models/consts/ctrlProp";
import { MbrType } from "../../../models/mbr";

interface ElementPropViewComponentProps {
  mbr: MbrType,
  element: ElementGrillePropType;
  conf: ConfigSocPropType;
  elementSelected: number;
  isModifiable: boolean;
  onElementChange: ( e: number, v: CriterGrillePropType[] ) => void;
  onElementClicked: () => void;
  onElementSelected: ( i: number ) => void;
};

const ElementPropViewComponent: FunctionComponent<ElementPropViewComponentProps> = ( { mbr, element, conf, isModifiable, elementSelected, onElementChange, onElementClicked, onElementSelected } ) => {
  const [listCriters, setListeCriters] = useState<CriterGrillePropType[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isMonted, setIsMonted] = useState(false);
  const [isStarted, setIsStarted] = useState(false);

  const criterRefs = useRef<(any | null)[]>([]);

  useEffect(() => {
    if( elementSelected === element.id ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [elementSelected]);
  useEffect(() => {
    if( isMonted && isStarted ) {
      onElementChange(element.id, listCriters);
    }
  }, [listCriters]);

  useEffect(() => {
    if( element.id > 0 ) {
      setListeCriters(element.criters);
    }
  }, []);



  /* HANDELS */
  const handelOpenElement = () => {
    const b = !isOpen;

    setIsOpen(b);
    onElementSelected(element.id);
    onElementClicked();
    setIsMonted(true);
  }
  const handelSelectSO = () => {
    setIsStarted(true);

    const newListCriters:CriterGrillePropType[] = listCriters.map(criter => {
      return {
        ...criter,
        val: null,
        comment: {
          ...criter.comment,
          txt: '',
          img: { id: 0, txt: '' },
        },
      };
    });

    setListeCriters(newListCriters);
    onElementClicked();
  };
  const handelSelectPresent = () => {
    setIsStarted(true);

    const newListCriters = listCriters.map(criter => {
      return {
        ...criter,
        val: 0,
      };
    });

    setListeCriters(newListCriters);
    onElementClicked();
  };
  const handelSelectAbsent = () => {
    setIsStarted(true);

    const newListCriters:CriterGrillePropType[] = listCriters.map(criter => {
      return {
        ...criter,
        val: 1,
        comment: {
          ...criter.comment,
          txt: '',
          img: { id: 0, txt: '' },
        },
      };
    });

    setListeCriters(newListCriters);
    onElementClicked();
  };

  const setNoteCriterChange = ( i: number, v: number | null, c: CommentCriterGrillePropType | null ) => {
    setIsStarted(true);

    const newListCriters = listCriters.map(criter => {
      return {
        ...criter,
        val: ( criter.id === i ) ? v : criter.val,
        comment: ( ( criter.id === i ) && ( c !== null ) ) ? c : criter.comment,
      };
    });

    setListeCriters(newListCriters);
    onElementClicked();
  };



  return (
    <div className="element-prop-view-component">
      <div className="element-prop-view-content">
        <div className="title-element-container" onClick={ handelOpenElement }>
          <NoteComponent
            lbl={ ( element.txt ) ? element.txt : '' }
            val={ ( element.note !== undefined ) ? element.note : null }
            parent={ NOTE_VIEW_GRILLE }
            conf={ conf }
          />
        </div>

        <div className="list-criters-container" style={ { display: ( isOpen ) ? "block" : 'none' } }>
          <div className="entete-grille-container row">
            <div className="col s6 m6 l6"></div>
            <div className="btn-select-all col s2 m2 l2" onClick={ handelSelectSO }>S O</div>
            <div className="btn-select-all col s2 m2 l2" onClick={ handelSelectPresent }>Présence</div>
            <div className="btn-select-all col s2 m2 l2" onClick={ handelSelectAbsent }>Absence</div>
          </div>

          <div className="list-criters-container">
            { ( Object.values(listCriters).length > 0 ) ? (
              <div className="list-criters">
                { Object.values(element.criters).map(( criter, index ) => {
                  return (
                    <CriterPropViewComponent
                      mbr={ mbr }
                      key={ criter.id }
                      ref={ (el: any) => criterRefs.current[index] = el }
                      criter={ criter }
                      isModifiable={ isModifiable }
                      onNoteChange={ setNoteCriterChange }
                    />
                  )
                }) }
              </div>
            ) : (
              <div>Aucun critère à afficher</div>
            ) }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ElementPropViewComponent;
