
import React, { FunctionComponent, useState, useEffect } from "react";

import { StatType } from "../../models/statistiques";
import { STAT_ENCART_TYPE_CONTROLEUR } from "../../models/consts/statistiques";

interface MiniStatProps {
  data: StatType;
};

type MiniStatType = {
  title: string,
  line1: string,
  line2: string,
  line3: string,
};
const initialMiniStat: MiniStatType = {
  title: '',
  line1: '',
  line2: '',
  line3: '',
}

const MiniStat: FunctionComponent<MiniStatProps> = ( { data } ) => {
  const [donneeComponent, setDonneeComponent] = useState<MiniStatType>(initialMiniStat);
  const [isDefined, setIsDefined] = useState(false);
  const [isControleur, setIsControleur] = useState(false);

  useEffect(() => {
    const newData: MiniStatType = {
      title: data.txt,
      line1: data.nb.toString() + ' / ' + data.tot.toString(),
      line2: data.moyen.toString() + '%',
      line3: data.objectif,
    }

    setDonneeComponent(prevState => ({
      ...prevState,
      ...newData,
    }));
    setIsDefined(data.key > 0);
    setIsControleur(data.type === STAT_ENCART_TYPE_CONTROLEUR);
  }, [data]);

  return (
    <div className="mini-stat-component card-panel grey lighten-5" >
      { ( isDefined ) &&
        <div>
          <div className="title-component" >{donneeComponent.title}</div>
          <div className="line-component" >
            <div className="label-component" >Nb. de contrôle</div>
            <div className="value-component" >{donneeComponent.line1}</div>
          </div>
          <div className="line-component" >
            <div className="label-component" >Note moyenne</div>
            <div className="value-component" >{donneeComponent.line2}</div>
          </div>
          { ( isControleur ) &&
            <div className="line-component" >
              <div className="label-component" >Objectif</div>
              <div className="value-component" >{donneeComponent.line3}</div>
            </div>
          }
        </div>
      }
    </div>
  );
};

export default MiniStat;
