
// recevied datas
export type ReceveidAdresseType = {
  adresse: string,
  cp: string,
  ville: string,
};

// typages
export type AdresseType = {
  adr: string,
  cp: string,
  ville: string,
};

// initilisation
export const initialAdresse : AdresseType = {
  adr: '',
  cp: '',
  ville: '',
};

// functions
export const convertToAdresseType = (data: ReceveidAdresseType): AdresseType => {
  return {
    adr: data.adresse,
    cp: data.cp,
    ville: data.ville
  }
};
