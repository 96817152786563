
import React, { FunctionComponent } from "react";
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";

import './screens/styles/app.css';

import { LOGIN_URL, RECUP_MAIL_URL, CONTROL_PROP_URL } from "./models/consts/aplliUrls";

import Login from "./screens/login";
import RecupMail from "./screens/recupMail";
import ControlProp from "./screens/controlProp";

import { NavigationProvider } from "./components/navigationContext";
import { AppWrapper } from "./components/notify";

const App: FunctionComponent = () => {
  return (
    <AppWrapper>
      <Router>
        <NavigationProvider>
          <AppBody />
        </NavigationProvider>
      </Router>
    </AppWrapper>
  );
}

const AppBody: FunctionComponent = () => {
  return (
    <div className="app-body">
      <div className="landscape-warning">
        Pour une meilleure expérience, veuillez pivoter votre appareil en mode portrait.
      </div>

      <Switch>
        <Route
          path={RECUP_MAIL_URL}
          component={RecupMail}
        />
        <Route
          path={CONTROL_PROP_URL}
          component={ControlProp}
        />
        <Route exact path={LOGIN_URL} component={Login} />
        <Redirect to={LOGIN_URL} />
      </Switch>
    </div>
  );
};

export default App;
