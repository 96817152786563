
import { STAT_ENCART_TYPE_CONTROLEUR } from "./consts/statistiques";
import { VALIDATION_TYPE_STAT } from "./validations";

// receveids datas
type ReceveidMoisStatType = {
  id: number,
  nb: number,
  val: number,
};
type ReceveidStatType = {
  type: string,
  key: number,
  txt: string,
  moyen: number,
  nb: number,
  tot: number,
  objectif: string,
  data: ReceveidMoisStatType[],
};
type ReceveidLinePlanActionsStatType = {
  id: number,
  rsd: string,
  date: string,
  echeance: string,
  txt: string,
};
type ReceveidPlanActionsStatType = {
  nb: number,
  data: ReceveidLinePlanActionsStatType[],
};

// typages
export type MoisStatType = {
  id: number,
  max: number,
  val: number,
}
export type StatType = {
  type: string,
  key: number,
  txt: string,
  moyen: number,
  nb: number,
  tot: number,
  objectif: string,
  data: MoisStatType[],
};
export type LinePlanActionsStatType = {
  id: number,
  rsd: string,
  date: string,
  echeance: string,
  txt: string,
};
export type PlanActionsStatType = {
  nb: number,
  data: LinePlanActionsStatType[];
};

// initialisation
export const initialMoisStat: MoisStatType = {
  id: 0,
  max: 100,
  val: 0,
};
export const initialStatistiques: StatType = {
  type: STAT_ENCART_TYPE_CONTROLEUR,
  key: 0,
  txt: '',
  moyen: 0,
  nb: 0,
  tot: 0,
  objectif: '',
  data: [],
};
export const initialLinePlanActions: LinePlanActionsStatType = {
  id: 0,
  rsd: '',
  date: 'dd-mm-aaaa',
  echeance: 'dd-mm-aaaa',
  txt: '',
};
export const initialPlanActions: PlanActionsStatType = {
  nb: 0,
  data: [],
};

// functions
export const convertToStatType = (data: ReceveidStatType, debugg?: boolean): StatType => {
  const debuggComponent = ( ( debugg !== null ) && ( debugg !== undefined ) ) ? debugg : false;

  if( debuggComponent ) {
    console.log('/models/statistiques.ts::convertToStatType::data type', data.type);
    console.log('/models/statistiques.ts::convertToStatType::::VALIDATION_TYPE_STAT', VALIDATION_TYPE_STAT);
  }

  if( VALIDATION_TYPE_STAT.includes(data.type) ) {
    var listData: MoisStatType[] = [];

    for( let x in data.data ) {
        const newData: MoisStatType = {
          id: data.data[x].id,
          max: data.data[x].nb,
          val: ( data.data[x].nb > 0 ) ? Math.round(data.data[x].val / data.data[x].nb) : 0,
        }

        listData.push(newData);
      }

    const newStat: StatType = {
      type: STAT_ENCART_TYPE_CONTROLEUR,
      key: data.key,
      txt: data.txt,
      moyen: data.moyen,
      nb: data.nb,
      tot: data.tot,
      objectif: data.objectif,
      data: listData,
    };

    return newStat;
  } else {
    throw new  Error("Ces données de statitiques ne sont pas valide !");
  }
}
export const convertToPlanActionsType = (data: ReceveidPlanActionsStatType): PlanActionsStatType => {
  var newDatas: LinePlanActionsStatType[] = [];

  for( let x in data.data ) {
    let newData: LinePlanActionsStatType = {
      id: data.data[x].id,
      rsd: data.data[x].rsd,
      date: data.data[x].date,
      echeance: data.data[x].echeance,
      txt: data.data[x].txt,
    }

    newDatas.push(newData);
  }

  const newPlans: PlanActionsStatType = {
    nb: data.nb,
    data: newDatas,
  };

  return newPlans;
}
