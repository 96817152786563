
import React, { FunctionComponent, useEffect, useState } from "react";

import './toggleSwitch.css';
import { CONFIG_PROP_CONFIG_AFFICHAGE, CONFIG_PROP_CONFIG_METEO, CONFIG_PROP_CONFIG_PRODUITS, CONFIG_PROP_CONFIG_VISITE } from "../../models/consts/ctrlProp";

interface ToggleSwitchComponentProps {
  param: string;
  lbl0: string;
  lbl1: string;
  value: boolean;
  onChangeValue: (param: string, val: number) => void;
};

const ToggleSwitchComponent: FunctionComponent<ToggleSwitchComponentProps> = ( { param, lbl0, lbl1, value, onChangeValue } ) => {
  const [valueComponent, setValueComponent] = useState(0);
  const [paramComponent, setParamComponent] = useState('');

  useEffect(() => {
    if( param === CONFIG_PROP_CONFIG_VISITE ) {
      setParamComponent('Visite');
    } else if( param === CONFIG_PROP_CONFIG_METEO ) {
      setParamComponent('Météo');
    } else if( param === CONFIG_PROP_CONFIG_PRODUITS ) {
      setParamComponent('Produits, matériels, équipements');
    } else if( param === CONFIG_PROP_CONFIG_AFFICHAGE ) {
      setParamComponent('Affichage parties communes');
    }
  }, [param]);
  useEffect(() => {
    setValueComponent(( value ) ? 1 : 0);
  }, [value]);

  const toggleValue = () => {
    setValueComponent(( prevValue ) => ( prevValue === 0 ) ? 1 : 0);
    onChangeValue(param, valueComponent);
  }

  return (
    <div className="toggle-switch-component card-panel grey lighten-5">
      <div style={ { display: 'flex', alignItems: 'center' } }>
        <span className="toggle-switch-title"><b>{ paramComponent }</b></span>
        <span className="toggle-switch-lbl0">{ lbl0 }</span>
        <button 
          onClick={ toggleValue } 
          className="toggle-switch" 
          data-value={ valueComponent }
        />
        <span className="toggle-switch-lbl1">{ lbl1 }</span>
      </div>
    </div>
  );
};

export default ToggleSwitchComponent;
