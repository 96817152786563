
import React, { FunctionComponent, useEffect, useState } from "react";

import { AgentType } from "../../../models/agent";
import './index.css';

interface SelectAgentDialProps {
  listPrestataire: AgentType[];
  listAgents: AgentType[];
  onSelect: ( item: AgentType ) => void;
  onClose: () => void;
};

const SelectAgentDial: FunctionComponent<SelectAgentDialProps> = ( { listPrestataire, listAgents, onSelect, onClose } ) => {
  const adrFile ='/components/dials/selectAgent/index.tsx::SelectAgent::';

  const [listPrestairesComponent, setListPrestairesComponent] = useState<AgentType[]>([]);
  const [listAgentsComponent, setListAgentsComponent] = useState<AgentType[]>([]);
  const [showPrestataires, setShowPrestataire] = useState(true);
  const [showAgents, setShowAgents] = useState(false);

  useEffect(() => {
    const prestatairesArray = Object.values(listPrestataire);
    
    setListPrestairesComponent(prevState => ({
      ...prevState,
      ...prestatairesArray,
    }));

    if( !( listPrestataire.length > 0 ) ) {
      setShowPrestataire(false);
      setShowAgents(true);
    }
  }, [listPrestataire]);
  useEffect(() => {
    const agentsArray = Object.values(listAgents);
    
    setListAgentsComponent(prevState => ({
      ...prevState,
      ...agentsArray,
    }));
  }, [listAgents]);

  const handleSelect = ( item: AgentType ) => {
    onSelect(item);
    onClose();
  };
  const handelTypeAgentChangeView = ( type: string ) => {
    if( type === 'p' ) {
      setShowAgents(false);
      setShowPrestataire(true);
    } else {
      setShowPrestataire(false);
      setShowAgents(true);
    }
  };

  return (
    <div className="select-agent-dial">
      <div className="select-agent-dial-content">
        <div className="select-type-agent" >
          <button
            className="btn btn-lage btn-std"
            style={ ( showPrestataires ) ? { width: '40%', opacity: '1' } : { width: '40%', opacity: '0.5' } }
            key="p"
            onClick={ () => handelTypeAgentChangeView('p') }
          >
            Prestataires
          </button>

          <button
            className="btn btn-lage btn-std"
            style={ ( showAgents ) ? { width: '60%', opacity: '1' } : { width: '60%', opacity: '0.5' } }
            key="a"
            onClick={ () => handelTypeAgentChangeView('a') }
          >
            Agents de proximité
          </button>
        </div>

        <div className="list-content list-presta" style={ ( showPrestataires ) ? { display: 'block' } : { display: 'none' } }>
          { ( Object.entries(listPrestairesComponent).length > 0 ) ? ( Object.values(listPrestairesComponent).map(( presta ) => (
            <button
              className="btn btn-large btn-list"
              key={ presta.id }
              onClick={ () => handleSelect(presta) }
            >
              { presta.txt }
            </button>
          )) ) : (
            <p>Aucun prestataire disponible</p>
          ) }
        </div>

        <div className="list-content list-agent" style={ ( showAgents ) ? { display: 'block' } : { display: 'none' } }>
          { ( Object.entries(listAgentsComponent).length > 0 ) ? ( Object.values(listAgentsComponent).map(( agent ) => (
            <button
              className="btn btn-large btn-list"
              key={ agent.id }
              onClick={ () => handleSelect(agent) }
            >
              { agent.txt }
            </button>
          )) ) : (
            <p>Aucun agent disponible</p>
          ) }
        </div>
      </div>
    </div>
  );
};

export default SelectAgentDial;
