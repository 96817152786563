import { AgenceType, ReceveidAgenceType } from "./agence";
import { AbonnementType, ReceveidAbonnementType } from "./abonnements";

type ReceivedMbr = {
  id: number,
  pseudo: string,
  mdp: string,
  mac: string,
  niveau?: number,
  idAgent?: number,
  txt?: string,
  mail?: string,
  tel?: string,
  agences?: Array<ReceveidAgenceType>,
  abonnements?: ReceveidAbonnementType,
}

export type MbrType = {
  id: number,
  pseudo: string,
  mdp: string,
  mac: string,
  niveau?: number,
  idAgent?: number,
  txt?: string,
  mail?: string,
  tel?: string,
  agences?: Array<AgenceType>,
  abonnements?: AbonnementType,
};

export const initialMbr: MbrType = {
  id: 0,
  pseudo: '',
  mdp: '',
  mac: '',
};

export const ConverteToMbrType = (data: ReceivedMbr): MbrType => {
  return {
    id: data.id,
    pseudo: data.pseudo,
    mdp: data.mdp,
    mac: data.mac,
    niveau: (data.niveau || undefined),
    idAgent: (data.idAgent || undefined),
    txt: (data.txt || undefined),
    mail: (data.mail || undefined),
    tel: (data.tel || undefined),
    agences: (data.agences )
  }
}
