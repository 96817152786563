
export const DONNEES_VISITE_PROGRAMME = false;
export const DONNEES_VISITE_INNOPINEE = true;
export const DONNEES_METEO_NORMALE = false;
export const DONNEES_METEO_PERTURBEE = true;
export const DONNEES_PRODUITS_CONFORME = true;
export const DONNEES_PRODUITS_NON_CONFORME = false;
export const DONNEES_AFFICHAGE_CONFORME = true;
export const DONNEES_AFFICHAGE_NON_CONFORME = false;

export const TYPE_ZONE_CTRL_PROP_PROXI = 'p';
export const TYPE_ZONE_CTRL_PROP_CONTRAT = 'c';
export const TYPE_ZONE_CTRL_PROP_ALL = 'a';

export const CONFIG_PROP_CONFIG_VISITE = 'visite';
export const CONFIG_PROP_CONFIG_METEO = 'meteo';
export const CONFIG_PROP_CONFIG_PRODUITS = 'produits';
export const CONFIG_PROP_CONFIG_AFFICHAGE = 'affichage';

export const CONFIG_PROP_VERSION_COMPLET = 'complet';
export const CONFIG_PROP_VERSION_ALEATOIRE = 'aleatoir';
export const CONFIG_PROP_VERSION_SPECIFIQUE = 'specifique';

export const CONFIG_PROP_TYPE_EDLE = 'edle';
export const CONFIG_PROP_TYPE_CTRL = 'ctrl';
export const CONFIG_PROP_TYPE_LEVEE = 'levee';
export const CONFIG_PROP_TYPE_EDLS = 'edls';

export const MENU_ONGLET_BUTTON_CONFIG = 'config';
export const MENU_ONGLET_BUTTON_SEND = 'send';
export const MENU_ONGLET_BUTTON_PLAN = 'plan';
export const MENU_ONGLET_BUTTON_SIGNATURE = 'signature';
export const MENU_ONGLET_HIDDEN = 'hidden';

export const NOTE_VIEW_INFO_LAST = 'info_last';
export const NOTE_VIEW_INFO_MOYENNE = 'info_moy';
export const NOTE_VEIW_CONTROL_NOTE = 'ctrl_note';
export const NOTE_VEIW_CONTROL_LAST = 'ctrl_last';
export const NOTE_VEIW_CONTROL_MOYENNE = 'ctrl_moy';
export const NOTE_VIEW_GRILLE = 'grille';
