
import React, { FunctionComponent, useEffect, useState } from "react";

import './index.css';

import { AgentType } from "../../models/agent";
import { DIALOG_TYPE_COMMENT, DIALOG_TYPE_SELECT_AGENT } from "./const";

import SelectAgentDial from "./selectAgent";
import CommentDial from "./comment";
import { MbrType } from "../../models/mbr";
import { ImgCommentPropType } from "../../models/ctrlProp";

interface DialsProps {
  mbr: MbrType,
  dialogType: string;
  isOpen: boolean;
  onClose: () => void;
  listPrestataire?: AgentType[];
  listAgents?: AgentType[];
  onSelectAgent?: ( val: AgentType ) => void;
  txt?: string;
  img?: ImgCommentPropType;
  onSupprComment?: () => void;
  onSetComment?: ( txt: string, img: ImgCommentPropType ) => void;
};

const ModalDialsComponent: FunctionComponent<DialsProps> = ( {
  mbr,
  dialogType,
  isOpen,
  onClose,
  listPrestataire,
  listAgents,
  onSelectAgent,
  txt,
  img,
  onSupprComment,
  onSetComment,
} ) => {
  const [classContent, setClassContent] = useState('');

  const [showSelectAgent, setShowSelectAgent] = useState(false);
  const [listPrestatairesComponent, setListPrestatairesComponent] = useState<AgentType[]>([]);
  const [listAgentsComponent, setListAgentsComponent] = useState<AgentType[]>([]);
  
  const [showCommentDial, setShowCommentDial] = useState(false);
  
  useEffect(() => {
    if( ( listPrestataire !== null ) && ( listPrestataire !== undefined ) && ( Object.entries(listPrestataire).length > 0 ) ) {
      setListPrestatairesComponent(prevState => ({
        ...prevState,
        ...listPrestataire,
      }));
    }
  }, [listPrestataire]);
  useEffect(() => {
    if( ( listAgents !== null ) && ( listAgents !== undefined ) && ( Object.entries(listAgents).length > 0 ) ) {
      setListAgentsComponent(prevState => ({
        ...prevState,
        ...listAgents,
      }));
    }
  }, [listAgents]);
  useEffect(() => {
    switch( dialogType ) {
      case DIALOG_TYPE_SELECT_AGENT:
        setShowCommentDial(false);
        setShowSelectAgent(true);
        setClassContent('view-select-agent');
        break;
      case DIALOG_TYPE_COMMENT:
        setShowSelectAgent(false);
        setShowCommentDial(true);
        break;
    }
  }, [dialogType]);

  if( !isOpen ) return null;

  const handelAgentSelected = ( v: AgentType ) => {
    if( onSelectAgent ) {
      onSelectAgent(v);
    }
  };

  const handelSetComment = ( t: string, i: ImgCommentPropType ) => {
    if( onSetComment ) {
      onSetComment(t, i);
    }
  };
  const handelSupprComment = () => {
    if( onSupprComment ) {
      onSupprComment();
    }
  };

  return (
    <div className="modal-dials-component" onClick={ onClose }>
      <div className={`modal-dials-content ${classContent}`} onClick={ ( e ) => e.stopPropagation() }>
        { ( showSelectAgent ) &&
          <SelectAgentDial
            onClose={ onClose }
            listPrestataire={ listPrestatairesComponent || [] }
            listAgents={ listAgentsComponent || [] }
            onSelect={ handelAgentSelected }
          />
        }
        { ( showCommentDial ) &&
          <CommentDial
            mbr={ mbr }
            txt={ ( txt !== undefined ) ? txt : '' }
            img={ ( img !== undefined ) ? img : { id: 0, txt: '' } }
            onValidComment={ handelSetComment }
            onSupprComment={ handelSupprComment }
            onAnnulComment={ onClose }
          />
        }
      </div>
    </div>
  );
};

export default ModalDialsComponent;
