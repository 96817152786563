
import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Line,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart
} from "recharts";

import { MoisStatType } from "../../models/statistiques";

interface MyComposedChartProps {
  data: MoisStatType[];
};

type GraphType = {
  month: string,
  valueBar: number,
  valueLine: number,
};

const MyComposedChart: FunctionComponent<MyComposedChartProps> = ( { data } ) => {
  const [donneeComponent, setDonneeComponent] = useState<GraphType[]>([]);

  useEffect(() => {
    let donnees: GraphType[] = [];

    for( let i = 0; i < data.length; i++ ) {
      var m = '';

      switch( data[i].id.toString() ) {
        case '1': m = 'Jan'; break;
        case '2': m = 'Fev'; break;
        case '3': m = 'Mars'; break;
        case '4': m = 'Avr'; break;
        case '5': m = 'Mai'; break;
        case '6': m = 'Juin'; break;
        case '7': m = 'Juil'; break;
        case '8': m = 'Aout'; break;
        case '9': m = 'Sep'; break;
        case '10': m = 'Oct'; break;
        case '11': m = 'Nov'; break;
        case '12': m = 'Dec'; break;
      }

      let newVal: GraphType = {
        month: m,
        valueBar: data[i].max,
        valueLine: data[i].val,
      }

      donnees.push(newVal);
    }

    setDonneeComponent(donnees);
  }, [data]);

  return (
    <ResponsiveContainer width="95%" height={300}>
      <ComposedChart
        data={donneeComponent}
        margin={{
          top: 5,
          right: 10,
          left: 0,
          bottom: 10,
        }}
      >
        <XAxis dataKey="month" />
        <YAxis domain={[0, 100]} />
        <Tooltip />
        <Legend />
        <Bar name="Nb de contrôles" dataKey="valueBar" fill="#8884d8" label />
        <Line name="Moyenne des contrôles" type="monotone" dataKey="valueLine" stroke="#ff7300" />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default MyComposedChart;
