
import React, { FunctionComponent, useEffect, useState } from "react";

import './index.css';

import { ConfigSocPropType, initialConfigSocProp } from "../../../models/configSocProp";
import { NOTE_VEIW_CONTROL_NOTE, NOTE_VIEW_GRILLE, NOTE_VIEW_INFO_LAST, NOTE_VIEW_INFO_MOYENNE } from "../../../models/consts/ctrlProp";

interface NoteComponentProps {
  lbl: string;
  val: number | null;
  conf: ConfigSocPropType;
  parent: string;
};

const NoteComponent: FunctionComponent<NoteComponentProps> = ( { lbl, val, conf, parent } ) => {
  const [colorNote, setColorNote] = useState('#bdbdbd');
  const [valComponent, setValComponent] = useState<number|null>(null);

  const [configSocComponent, setConfigSocComponent] = useState<ConfigSocPropType>(initialConfigSocProp);

  useEffect(() => {
    setConfigSocComponent(prevState => ({
      ...prevState,
      ...conf,
    }));
  }, [conf]);
  useEffect(() => {
    const noteBasse = configSocComponent.limites.basse;
    const noteHaute = configSocComponent.limites.haute;

    if( val !== null ) {
      let v = val;

      if( v > 100 ) {
        v = 100;
      } else {
        v = Math.round(v);
      }

      if( v < noteBasse ) {
        setColorNote('red');
      } else if( v >= noteHaute ) {
        setColorNote('#45b40b');
      } else {
        setColorNote('orange');
      }

      setValComponent(v);
    } else {
      setColorNote('grey');
      setValComponent(null);
    }
  }, [val]);

  return (
    <div className="note-component">
      { ( lbl !== '' ) &&
        <div className="lbl-note">{ lbl }</div>
      }
      <div 
        className={`val-note ${ ( parent === NOTE_VEIW_CONTROL_NOTE ) 
                                ? ( ( valComponent === null ) 
                                  ? 'not-controled' 
                                  : 'controled' ) 
                                : ( ( ( parent === NOTE_VIEW_INFO_LAST ) || ( parent === NOTE_VIEW_INFO_MOYENNE ) || ( parent === NOTE_VIEW_GRILLE ) ) 
                                  ? 'info-block' 
                                  : 'ctrl-block' ) }`}
        style={ { 
          color: colorNote, 
          ...( ( ( parent === NOTE_VEIW_CONTROL_NOTE ) && ( valComponent === 100 ) ) && { fontSize: '25pt' } ) } }
      >
        { ( valComponent === null ) ? 'S O' : valComponent.toString()+'%' }
      </div>
    </div>
  );
};

export default NoteComponent;
