
import React, { FunctionComponent, useEffect, useState } from "react";

import { MbrType } from "../../models/mbr";
import { EntryType } from "../../models/entry";
import { AgentType, initialAgent } from "../../models/agent";
import { DIALOG_TYPE_SELECT_AGENT } from "../dials/const";

import LineIdResidComponent from "./lineIdResid";
import ModalDialsComponent from "../dials";

interface IdResidComponentProps {
  mbr: MbrType;
  fiche: EntryType;
  listPrestataires: AgentType[];
  listAgents: AgentType[];
  presta: AgentType;
  onSelectedPresta: ( val: AgentType ) => void;
};

const IdResidComponent: FunctionComponent<IdResidComponentProps> = ( { mbr, fiche, listPrestataires, listAgents, presta, onSelectedPresta } ) => {
  const [prestaComponent, setPrestaComponent] = useState<AgentType>(initialAgent);
  const [selectAgentDialIsOpen, setSelectAgentDialIsOpen] = useState(false);
  const [listPrestatairesComponent, setListPrestairesComponent] = useState<AgentType[]>([]);
  const [listAgentsComponent, setListAgentsComponent] = useState<AgentType[]>([]);

  useEffect(() => {
    if( presta.id > 0 ) {
      setPrestaComponent(presta);
    }
  }, [presta]);
  useEffect(() => {
    if( ( listPrestataires !== null ) && ( listPrestataires !== undefined ) && ( Object.entries(listPrestataires).length > 0 ) ) {
      setListPrestairesComponent((prevState) => ({
        ...prevState,
        ...listPrestataires,
      }));
    }
  }, [listPrestataires]);
  useEffect(() => {
    if( ( listAgents !== null ) && ( listAgents !== undefined ) && ( Object.entries(listAgents).length > 0 ) ) {
      setListAgentsComponent((prevState) => ({
        ...prevState,
        ...listAgents,
      }));
    }
  }, [listAgents]);

  const handleOpenSelectAgent = () => {
    setSelectAgentDialIsOpen(true);
  };
  const handelSelectPresta = ( val: AgentType ) => {
    onSelectedPresta(val);
  };

  return (
    <div className="id-resid-component">
      <div className="cont_ref_resid card-panel grey lighten-5" style={ { padding: '5px' } }>
        <LineIdResidComponent lbl="Référence" val={ fiche.ref }/>
        <LineIdResidComponent lbl="Nom" val={ fiche.txt }/>
      </div>

      <div className="cont_adr_resid card-panel grey lighten-5" style={ { padding: '5px' } }>
        <LineIdResidComponent lbl="Entrée" val={ fiche.entry }/>
        <LineIdResidComponent lbl="Adresse" val={ `${fiche.adresse.adr} ${fiche.adresse.cp} ${fiche.adresse.ville}` }/>
      </div>

      <div className="cont_classement_resid card-panel grey lighten-5" style={ { padding: '5px' } }>
        <LineIdResidComponent lbl="Agence" val={ fiche.agence.txt }/>
        <LineIdResidComponent lbl="Groupement" val={ fiche.groupement.txt }/>
        <LineIdResidComponent lbl="Résidence" val={ fiche.residence.txt }/>
      </div>

      <div
        className="cont_referents_resid card-panel grey lighten-5"
        style={ { padding: '5px', cursor: 'pointer' } }
        onClick={ handleOpenSelectAgent }
      >
        <LineIdResidComponent lbl="Référent secteur" val={ fiche.referents.encadrant.txt }/>
        <LineIdResidComponent lbl="Référent administratif" val={ fiche.referents.gardien.txt }/>
        <LineIdResidComponent lbl="Référent entretien" val={ prestaComponent.txt }/>
      </div>

      <ModalDialsComponent
        isOpen={ selectAgentDialIsOpen }
        onClose={ () => setSelectAgentDialIsOpen(false) }
        listPrestataire={ listPrestatairesComponent }
        listAgents={ listAgentsComponent }
        onSelectAgent={ ( val: AgentType ) => handelSelectPresta(val) }
        dialogType={ DIALOG_TYPE_SELECT_AGENT }
      />
    </div>
  );
};

export default IdResidComponent;
