import React, { FunctionComponent, useEffect, useState } from "react";

import "./styles/recupMail.css";
import logo from "../assets/logo_appli.png";

import { RECUP_MAIL_ALLOWED_PATHS } from "../models/consts/aplliUrls";

import fetchRecupMail from "../services/recupMail";
import useConditionalRedirect from "../helpers/useConditionalRedirect";

const RecupMail : FunctionComponent = () => {
  useConditionalRedirect(RECUP_MAIL_ALLOWED_PATHS);

  const [message, setMessage] = useState<string>('👉 Entrez votre adresse mail de contact');
  const [mail, setMail] = useState('');
  const [error, setError] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setMail(e.target.value);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage('👉 Tentative de connexion en cours ...');

    try {
      const response = await fetchRecupMail(mail);

      if( response.result ) {
        setError(false);
        setMessage('👉 Vos identifiants vous ont été envoyés.');
      } else {
        setError(true);
        setMessage('👉 '+response.data.txt);
      }
    } catch( error ) {
      setError(true);
      setMessage('Une erreur s\'est produite lors de la connexion:');
    }
  }

  return (
    <div className="recup-mail-screen">
      <img src={logo} alt="logo" className="logo" />

      <div className="valign-wrapper">
        <div className="row">
          <div className="app-content col s12 m12 l12 offset-m1 offset-l1">
            <form onSubmit={ (e) => handleSubmit(e) } className="formulaire">
              <div className="card hoverable">
                <div className="card-stacked">
                  <div className="card-content">
                    <div className="form-group">
                      <div className="card-panel grey lighten-5" style={ (error) ? { color: "red" } : { color: "black" } }>
                        {message}
                      </div>
                    </div>

                    <div className="form-group input-view">
                      <input 
                        id="mail" 
                        type="mail" 
                        name="mail" 
                        className="form-control" 
                        value={ mail } 
                        onChange={ e => handleInputChange(e) }
                      />
                    </div>
                  </div>

                  <div className="card-action center">
                    <button type="submit" className="btn btn-std">ENVOYER</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecupMail;
