
import React, { FunctionComponent, useEffect, useState } from "react";
import CryptoJS from 'crypto-js';

import './grilleControl.css';
import logo from '../../assets/logo_appli.png';

import { SECRET_KEY } from "../../models/consts";
import { CONFIG_PROP_TYPE_CTRL, CONFIG_PROP_VERSION_COMPLET, MENU_ONGLET_BUTTON_CONFIG, MENU_ONGLET_BUTTON_PLAN, MENU_ONGLET_BUTTON_SEND, MENU_ONGLET_BUTTON_SIGNATURE, MENU_ONGLET_HIDDEN, NOTE_VEIW_CONTROL_LAST, NOTE_VEIW_CONTROL_MOYENNE, NOTE_VEIW_CONTROL_NOTE, NOTE_VIEW_INFO_LAST, NOTE_VIEW_INFO_MOYENNE } from "../../models/consts/ctrlProp";
import { STORE_PARAM_CONF_PROP, STORE_PARAM_GRILLE_PROP, STORE_PARAM_LIST_SAVES } from "../../models/consts/store";
import { MbrType } from "../../models/mbr";
import { ConfigPropType, converteToGrilleTypeFromJson, convertGrillePropTypeToJson, convertToGrillePropType, convertToPrestatairePropType, GrillePropType, initialConfigProp, initialGrilleProp, initialPrestatairePropType, MakeGrilleCtrlProp, PrestataireCtrlPropType, ZoneGrillePropType } from "../../models/ctrlProp";
import { mapToGrillePropType } from "../../models/ctrlProp";
import { convertToEntryType, EntryType, initialEntry } from "../../models/entry";
import { AgentType, initialAgent, mapListAgents } from "../../models/agent";
import { ConfigSocPropType, convertToConfigSocPropType, initialConfigSocProp } from "../../models/configSocProp";

import { fetchGetEntry, fetchGetGrille } from "../../services/getRsd";

import IdResidComponent from "../idResid/idResid";
import ConfigPropComponent from "./configProp/configProp";
import LocalisationsCtrlProp from "./localisations";
import NoteComponent from "./notes";
import MenuOngletComponent from "../menu/onglet";
import { VALIDATION_VIEW_SELECTED } from "../../models/validations";
import SendRapportComponent from "../sendRapport";
import PlanActionsComponent from "./planActions";
import { fetchSetCtrlProp, fetchSetSignature } from "../../services/setCtrlProp";
import SignatureComponent from "../signature/signature";
import { useNotification } from "../notify";
import { buildAssociativeArray } from "../../helpers/build-associative-array";

interface GrilleControlProps {
  mbr: MbrType;
  id: number;
  menuOpen: boolean;
};

const GrilleControlComponent: FunctionComponent<GrilleControlProps> = ( { mbr, id, menuOpen } ) => {
  const notify = useNotification();

  const [structure, setStructure] = useState<GrillePropType>({ ...initialGrilleProp, grille: [...initialGrilleProp.grille] });

  const [fiche, setFiche] = useState<EntryType>({ ...initialEntry });
  const [grille, setGrille] = useState<GrillePropType>({ ...initialGrilleProp, grille: [...initialGrilleProp.grille] });
  const [noteCtrl, setNoteCtrl] = useState<number | null>(null);
  
  const [listPrestataires, setListPrestataires] = useState<AgentType[]>([]);
  const [listAgents, setListAgents] = useState<AgentType[]>([]);
  
  const [presta, setPresta] = useState<AgentType>({ ...initialAgent });
  const [configSocProp, setConfigSocProp] = useState<ConfigSocPropType>({ ...initialConfigSocProp });

  const [isSigned, setIsSigned] = useState(false);
  const [isMonted, setIsMonted] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  
  const [showConfigBlock, setShowConfigBlock] = useState(true);
  const [showMailBlock, setShowMailBlock] = useState(false);
  const [showPlanActionsBlock, setShowPlanActionsBlock] = useState(false);
  const [showSignatures, setShowSignature] = useState(false);
  const [showGrille, setShowGrille] = useState(false);
  const [materializeClassGrille, setMaterializeClassGrille] = useState('s12 m12 l8');
  const [menuOngletViewSelected, setMenuOngletViewSelected] = useState(MENU_ONGLET_BUTTON_CONFIG);


  useEffect(() => {
    if( isMonted && isStarted ) {
      protectCtrl();
    }
  }, [grille]);
  useEffect(() => {
    if( isMonted && isStarted ) {
      saveCtrl();
    }
  }, [menuOpen]);
  
  useEffect(() => {
    if( ( mbr.id > 0 ) && ( id > 0 ) && !isMonted ) {
      fetchData();
    }
  }, []);

  const handelSelectPresta = ( val: AgentType ) => {
    setPresta(val);
  };
  const handelTypeChange = ( val: string ) => {
    if( grille !== null ) {
      setGrille(prevState => ({
        ...prevState,
        type: val,
      }));

      if( val === CONFIG_PROP_TYPE_CTRL ) {
        if( grille.version === '' ) {
          setShowGrille(false);
        } else {
          setShowGrille(true);
        }
      } else {
        setShowGrille(true);
      }
    }
  };
  const handelVersionChange = ( val: string ) => {
    setGrille(prevState => ({
      ...prevState,
      version: val,
    }));

    setShowGrille(true);
  };
  const handelConfigChange = ( val: ConfigPropType ) => {
    setGrille(prevState => ({
      ...prevState,
      config: val,
    }));
  };
  const handelGrilleClicked = () => {
    setShowConfigBlock(false);
    setShowMailBlock(false);
    setShowPlanActionsBlock(false);
    setShowSignature(false);
    setMaterializeClassGrille('s12 m12 l10');
    setMenuOngletViewSelected(MENU_ONGLET_HIDDEN);
  };
  
  const fetchData = async () => {
    const encryptedDataCible = localStorage.getItem(STORE_PARAM_GRILLE_PROP);
    const encryptedDataConfSocProp = localStorage.getItem(STORE_PARAM_CONF_PROP);

    if( ( encryptedDataCible !== null ) && ( encryptedDataConfSocProp !== null ) ) {
      const decryptedDataCible = JSON.parse(CryptoJS.AES.decrypt(encryptedDataCible, SECRET_KEY).toString(CryptoJS.enc.Utf8));
      const decryptedDataConfSocProp = JSON.parse(CryptoJS.AES.decrypt(encryptedDataConfSocProp, SECRET_KEY).toString(CryptoJS.enc.Utf8));

      const newStruct = mapToGrillePropType(decryptedDataCible);
      const newConfigSocProp = convertToConfigSocPropType(decryptedDataConfSocProp);

      setStructure(newStruct);
      setConfigSocProp(newConfigSocProp);
      setGrille(prevState => ({
        ...prevState,
        controleur: mbr.idAgent,
      }));

      fetchEntry(newStruct);
    }
  };
  const fetchEntry = async ( structPassed: GrillePropType ) => {
    try {
      const response = await fetchGetEntry(mbr.id, mbr.mac, id.toString());

      if( response.result ) {
        const newFiche = convertToEntryType(response.data);
        const newListPrestataires = mapListAgents(response.data.list.presta);
        const newListAgents = mapListAgents(response.data.list.agt);
        const prest = convertToPrestatairePropType(response.data.agt);

        setGrille(prevState => ({
          ...prevState,
          rsd: response.data.id,
          presta: prest,
        }));
        setListPrestataires(newListPrestataires);
        setListAgents(newListAgents);
        setFiche(newFiche);

        fetchGrille(newFiche.id, prest, response.data.date, structPassed);

        if( response.data.agt.typ === 'p' ) {
          for( let x in newListPrestataires ) {
            if( newListPrestataires[x].id === response.data.agt.id ) {
              setPresta(newListPrestataires[x]);
            }
          }
        } else {
          for( let x in newListAgents ) {
            if( newListAgents[x].id === response.data.agt.id ) {
              setPresta(newListAgents[x]);
            }
          }
        }
      } else {
        console.error('fetchEntry::response::ERROR', response.data.txt);
      }
    } catch(error) {
      console.error('fetchEntry::ERROR', error);
    }
  };
  const fetchGrille = async ( idPassed: number, prestaPassed: PrestataireCtrlPropType, datePassed: number, structPassed: GrillePropType ) => {
    try {
      const listSaves = localStorage.getItem(STORE_PARAM_LIST_SAVES);
      let b = false;
      let m = '';

      if( listSaves !== null ) {
        let t = listSaves.split(',');

        for( let i = 0, c = t.length; i < c; i++ ) if( !b ) {
          if( t[i] !== '' ) {
            if( parseInt(t[i]) === idPassed ) {
              let encryptedGgrille = localStorage.getItem('prop_'+t[i]);

              if( encryptedGgrille !== null ) {
                let jsonGrille = JSON.parse(encryptedGgrille);

                if( ( 'date' in jsonGrille ) && ( jsonGrille.date === datePassed ) && ( 'signatures' in jsonGrille ) && ( jsonGrille.signatures === '' ) ) {
                  b = true;
                  m = t[i];
                }
              }
            }
          }
        }
      }
      if( b ) {
        const grilleJson = localStorage.getItem('prop_'+m);

        if( grilleJson !== null ) {
          const jsonGrille = JSON.parse(grilleJson);
          const savedGrille = converteToGrilleTypeFromJson(jsonGrille);

          setGrille(savedGrille);
          setNoteCtrl(( ( savedGrille.note === undefined ) ? null : savedGrille.note ));
          setIsMonted(true);

          if( ( 'saved' in jsonGrille ) && ( jsonGrille.saved === false ) ) {
            setIsStarted(true);
            saveCtrl(savedGrille);
          }
        } else {
          b = false;
        }
      }
      if( !b ) {
        const response = await fetchGetGrille(mbr.id, mbr.mac, idPassed);

        if( response.result ) {
          const receivedGrille = convertToGrillePropType(response.data, prestaPassed);
          const newGrille = MakeGrilleCtrlProp(buildAssociativeArray(structPassed.grille), receivedGrille);

          setGrille(newGrille);
          setNoteCtrl(( ( newGrille.note === undefined ) ? null : newGrille.note ));
          setIsMonted(true);
        } else {
          console.error('fetchGrille::response::ERROR', response.data.txt);
        }
      }
    } catch(error) {
      console.error('fetchGrille::ERROR', error);
    }
  };

  const selectOngletView = (v: string) => {
    if( VALIDATION_VIEW_SELECTED.includes(v) ) {
      setMaterializeClassGrille('s12 m12 l8');
      setMenuOngletViewSelected(v);

      if( v === MENU_ONGLET_BUTTON_CONFIG ) {
        setShowMailBlock(false);
        setShowPlanActionsBlock(false);
        setShowSignature(false);
        setShowConfigBlock(true);
      } else if( v === MENU_ONGLET_BUTTON_SEND ) {
        setShowConfigBlock(false);
        setShowPlanActionsBlock(false);
        setShowSignature(false);
        setShowMailBlock(true);
      } else if( v === MENU_ONGLET_BUTTON_PLAN ) {
        if( ( ( grille.id !== undefined ) && ( grille.id > 0 ) ) || ( grille.plan !== undefined ) ) {
          setShowConfigBlock(false);
          setShowMailBlock(false);
          setShowSignature(false);
          setShowPlanActionsBlock(true);
        } else {
          notify("Vous devez commencer votre contrôle avant d'établir un plan d'actions", { variant: 'info' });
        }
      } else if( v === MENU_ONGLET_BUTTON_SIGNATURE ) {
        if( ( grille.id !== null ) && ( grille.id !== undefined ) && ( grille.id > 0 ) ) {
          setShowConfigBlock(false);
          setShowMailBlock(false);
          setShowPlanActionsBlock(false);
          setShowSignature(true);
        } else {
          notify('Vous devez avoir commencer votre contrôle avant de le signer !', { variant: 'info' });
        }
      } else {
        setShowConfigBlock(false);
        setShowMailBlock(false);
        setShowPlanActionsBlock(false);
        setShowSignature(false);
        setMaterializeClassGrille('s12 m12 l10');
        setMenuOngletViewSelected(MENU_ONGLET_HIDDEN);
      }
    }
  }
  const setGrilleChangement = ( g: ZoneGrillePropType[], n: number | null ) => {
    setIsStarted(true);
    setNoteCtrl(n);
    setGrille(prevState => ({
      ...prevState,
      grille: g,
      note: n,
    }));

    handelGrilleClicked();
  };

  const protectCtrl = () => {
    if( ( grille.rsd !== null ) && ( grille.rsd !== undefined ) ) {
      const listSavesFromStorage = localStorage.getItem(STORE_PARAM_LIST_SAVES);
      let listSaves = '';
      let rsdToAdd = grille.rsd.toString();
      let i_rsd = 0;

      if( listSavesFromStorage !== null ) {
        let t = listSavesFromStorage.split(',');

        for( let i = 0, c = t.length; i < c; i++ ) {
          if( parseInt(t[i]) > 0 ) {
            if( t[i].split('_')[0] === rsdToAdd ) {
              let encryptedRsdSaved = localStorage.getItem(('prop_'+rsdToAdd));

              if( encryptedRsdSaved !== null ) {
                let rsdSaved = JSON.parse(encryptedRsdSaved);

                if( ( parseInt(rsdSaved.date) !== grille.date ) && ( rsdSaved.note !== null ) && !rsdSaved.saved ) {
                  i_rsd++;
                  rsdToAdd = grille.rsd + '_' + i_rsd.toString();
                  
                  if( listSaves !== '' ) listSaves += ',';
                  listSaves += t[i];
                }
              }
            } else {
              if( listSaves !== '' ) listSaves += ',';
              listSaves += t[i];
            }
          }
        }
      }

      if( listSaves !== '' ) listSaves += ',';
      listSaves += rsdToAdd;

      const newGrille = convertGrillePropTypeToJson(grille);
      const jsonGrille = JSON.stringify(newGrille);

      let m = 'prop_' + rsdToAdd;

      localStorage.setItem(m, jsonGrille);
      localStorage.setItem(STORE_PARAM_LIST_SAVES, listSaves);
    }
  };
  const saveCtrl = async ( grillePassed?: GrillePropType ) => {
    const grilleToSave = ( grillePassed !== undefined ) ? grillePassed : grille;

    if( ( grilleToSave.rsd !== null ) && ( grilleToSave.rsd !== undefined ) ) {
      try{
        const newGrille = convertGrillePropTypeToJson(grilleToSave, true);
        const jsonGrille = JSON.stringify(newGrille);
        const response = await fetchSetCtrlProp(mbr.id, mbr.mac, jsonGrille);

        if( ( response.result !== undefined ) && response.result ) {
          var m = 'prop_' + grilleToSave.rsd;
          const encryptedGrilleSaved = localStorage.getItem(m);

          if( encryptedGrilleSaved !== null ) {
            const grilleSaved = JSON.parse(encryptedGrilleSaved);

            grilleSaved.saved = true;
            grilleSaved.id = response.data.id;

            localStorage.setItem(m, JSON.stringify(grilleSaved));

            if( ( grille.rsd !== undefined ) && ( grille.rsd === grilleSaved.id ) ) {
              setGrille(prevState => ({
                ...prevState,
                id: response.data.id,
              }));
            }
          }
        } else if( response.data !== undefined ) {
          console.error('saveCtrl::ERROR', response.data.txt);
        } else {
          console.error('saveCtrl::ERROR == réponse non valide');
        }
      } catch( error ) {
        console.error('saveCtrl::ERROR', error);
      }
    }
  };
  const saveSignatures = async ( sigs: string[] ) => {
    if( ( grille.id !== null ) && ( grille.id !== undefined ) ) {
      try {
        const response = await fetchSetSignature(mbr.id, mbr.mac, grille.id, sigs[0], ( ( sigs.length > 1 ) ? sigs[1] : undefined ));

        if( ( response.result !== undefined ) && response.result ) {
          setGrille(prevState => ({
            ...prevState,
            signatures: response.data,
          }));
          setIsSigned(true);
        } else if( response.data !== undefined ) {
          console.error('saveSignature::ERROR', response.data.txt);
        } else {
          console.error('saveSignature::ERROR == réponse non valide');
        }
      } catch( error ) {
        console.error('saveSignature::ERROR', error);
      }
    }
  };

  return (
    <div className="grille-control-component row">
      <MenuOngletComponent view={ menuOngletViewSelected } onViewSelected={ selectOngletView }/>

      <div className="block-config col s12 m12 l4" style={ { display: ( showConfigBlock ) ? "block" : "none" } }>
        <IdResidComponent
          mbr={ mbr } 
          fiche={ fiche } 
          listPrestataires={ listPrestataires } 
          listAgents={ listAgents }
          presta={ presta }
          onSelectedPresta={ handelSelectPresta }
        />
        <ConfigPropComponent
          version={ ( grille.version ) ? grille.version : CONFIG_PROP_VERSION_COMPLET }
          type={ ( grille.type ) ? grille.type : CONFIG_PROP_TYPE_CTRL }
          config={ ( grille.config ) ? grille.config : { ...initialConfigProp } }
          isModifiable={ isSigned }
          onVersionCtrlChange={ handelVersionChange }
          onTypeCtrlChange={ handelTypeChange }
          onConfigCtrlChange={ handelConfigChange }
        />
      </div>

      <div className="block-mail col s12 m12 l4" style={ { display: ( showMailBlock ) ? "block" : "none" } }>
        <SendRapportComponent/>
      </div>

      <div className="block-plan-actions col s12 m12 l4" style={ { display: ( showPlanActionsBlock ) ? "block" : "none" } }>
        <PlanActionsComponent
          id={ ( grille.plan ) ? grille.plan.id : 0 }
          date={ ( grille.plan ) ? grille.plan.echeance : '' }
          mess={ ( grille.plan ) ? grille.plan.txt : '' }
        />
      </div>

      <div className="block-signature col s12 m12 l4" style={ { display: ( showSignatures ) ? "block" : "none" } }>
        <SignatureComponent numberOfSignatures={2} isSigned={ isSigned } onSignature={ saveSignatures }/>
      </div>

      <div className="block-notes col s12 m12 l2" style={ { display: ( !showConfigBlock && !showMailBlock && !showPlanActionsBlock && !showSignatures ) ? "block" : "none" } }>
        <div className="ctrl-note-container card-panel grey lighten-5">
          <NoteComponent 
            lbl="" 
            val={ noteCtrl } 
            conf={ configSocProp } 
            parent={ NOTE_VEIW_CONTROL_NOTE }
          />
        </div>

        <div className="last-note-container card-panel grey lighten-5">
          <NoteComponent 
            lbl="Dernier contrôle" 
            val={ fiche.notesProp.last } 
            conf={ configSocProp } 
            parent={ NOTE_VEIW_CONTROL_LAST }
          />
        </div>

        <div className="moy-note-container card-panel grey lighten-5">
          <NoteComponent 
            lbl="Moyenne des contrôles" 
            val={ fiche.notesProp.moy } 
            conf={ configSocProp } 
            parent={ NOTE_VEIW_CONTROL_MOYENNE }
          />
        </div>
      </div>

      <div className="block-img col s12 m12 l8" style={ { display: ( !showGrille ) ? "block" : "none" } }>
        <img src={ logo } alt="logo"/>
      </div>

      <div className={`block-grille col ${ materializeClassGrille }`} style={ { display: ( showGrille ) ? "block" : "none" } }>
        <div className="notes-grille-container" style={ { display: ( showConfigBlock || showMailBlock || showPlanActionsBlock || showSignatures ) ? "block" : "none" } }>
          <div className="last-note-container">
            <NoteComponent
              lbl="Dernier contrôle"
              val={ fiche.notesProp.last }
              conf={ configSocProp } 
              parent={ NOTE_VIEW_INFO_LAST }
            />
          </div>

          <div className="moy-note-container">
            <NoteComponent 
              lbl="Moyenne des contrôles" 
              val={ fiche.notesProp.moy } 
              conf={ configSocProp } 
              parent={ NOTE_VIEW_INFO_MOYENNE }
            />
          </div>
        </div>

        { ( isMonted ) && 
          <LocalisationsCtrlProp
            mbr={ mbr }
            structure={ structure }
            grille={ grille }
            conf={ configSocProp }
            isSigned={ isSigned }
            onGrilleChange={ setGrilleChangement }
            onGrilleClicked={ handelGrilleClicked }
            onZoneChanging={ saveCtrl }
          />
        }
      </div>
    </div>
  )
};

export default GrilleControlComponent;
