
import { ZoneGrillePropType } from "../models/ctrlProp";

export interface AssociativeCriterType {
  [id: number]: {
    txt: string | undefined,
    coef: number | undefined,
  }
};
export interface AssociativeElementType {
  [id: number]: {
    txt: string | undefined,
    coef: number | undefined,
    data: AssociativeCriterType,
  },
};
export interface AssociativeArrayType {
  [id: number]: {
    txt: string | undefined,
    coef: number | undefined,
    data: AssociativeElementType,
  },
};

export function buildAssociativeArray( obj: ZoneGrillePropType[] ): AssociativeArrayType {
  const result: AssociativeArrayType = {};

  Object.values(obj).forEach(( zone ) => {
    var dataZone: AssociativeElementType = [];

    Object.values(zone.elements).forEach(( element ) => {
      var dataElement: AssociativeCriterType = [];

      Object.values(element.criters).forEach(( criter ) => {
        dataElement[criter.id] = {
          txt: criter.txt,
          coef: criter.coef,
        };
      });

      dataZone[element.id] = {
        txt: element.txt,
        coef: element.coef,
        data: dataElement,
      };
    });

    result[zone.id] = {
      txt: zone.txt,
      coef: zone.coef,
      data: dataZone,
    };
  });

  return result;
}
