import React, { FunctionComponent, createContext, useContext, useState, ReactNode } from 'react';

interface NavigationContextProps {
  previousPath: string | null;
  setPreviousPath: (path: string | null) => void;
}

const NavigationContext = createContext<NavigationContextProps | undefined>(undefined);

export const NavigationProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
  const [previousPath, setPreviousPath] = useState<string | null>(null);

  return (
    <NavigationContext.Provider value={{ previousPath, setPreviousPath }}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigation = (): NavigationContextProps => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error('useNavigation must be used within a NavigationProvider');
  }
  return context;
};
