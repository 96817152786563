
import axios from "axios";
import { API_URL_GET_FICHE_RESIDENCE, API_URL_GET_GRILLE_CTRLPROP } from "../models/consts/apiUrls";

export async function fetchGetEntry( i: number, m: string, v: string ) {
  try {
    const url = API_URL_GET_FICHE_RESIDENCE;
    const params = {
      mbr: i,
      mac: m,
      val: v,
    };
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });

    return response.data;
  } catch( error ) {
    console.error(adrFile+'ERROR', error);

    return {
      result: false,
      donn: {
        numero: 404,
        txt: 'getRsd.tsx::fetchGetEntry::Une erreur s\'est produite lors de la connexion:',
      },
    };
  }
};
export async function fetchGetGrille( i: number, m: string, v: number ) {
  try {
    const url = API_URL_GET_GRILLE_CTRLPROP;
    const params = {
      mbr: i,
      mac: m,
      val: v,
    };
    const response = await axios.post(url, params, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    });

    return response.data;
  } catch(error) {
    console.error(adrFile+'ERROR', error);
    
    return {
      result: false,
      donn: {
        numero: 404,
        txt: 'getRsd.tsx::fetchGetGrille::Une erreur s\'est produite lors de la connexion:',
      },
    }
  }
}
