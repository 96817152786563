
import React, { FunctionComponent, useEffect, useState } from "react";

import './configProp.css';

import { CONFIG_PROP_CONFIG_AFFICHAGE, CONFIG_PROP_CONFIG_METEO, CONFIG_PROP_CONFIG_PRODUITS, CONFIG_PROP_CONFIG_VISITE, CONFIG_PROP_TYPE_CTRL, CONFIG_PROP_TYPE_EDLE, CONFIG_PROP_TYPE_EDLS, CONFIG_PROP_TYPE_LEVEE, CONFIG_PROP_VERSION_ALEATOIRE, CONFIG_PROP_VERSION_COMPLET, CONFIG_PROP_VERSION_SPECIFIQUE } from "../../../models/consts/ctrlProp";
import { initialConfigProp, ConfigPropType } from "../../../models/ctrlProp";
import { VALIDATION_CONFIG_PROP_TYPE, VALIDATION_CONFIG_PROP_VERSION } from "../../../models/validations";

import ToggleSwitchComponent from "../../toggleSwitch/toggleSwitch";

interface ConfigPropComponentProps {
  version: string;
  type: string;
  config: ConfigPropType;
  isModifiable: boolean;
  onVersionCtrlChange: (version: string) => void;
  onTypeCtrlChange: (type: string) => void;
  onConfigCtrlChange: (config: ConfigPropType) => void;
};

const ConfigPropComponent: FunctionComponent<ConfigPropComponentProps> = ( { version, type, config, isModifiable, onVersionCtrlChange, onTypeCtrlChange, onConfigCtrlChange } ) => {
  const [typeComponent, setTypeComponent] = useState(CONFIG_PROP_TYPE_CTRL);
  const [versionComponent, setVersionComponent] = useState(CONFIG_PROP_VERSION_COMPLET);
  const [configComponent, setConfigComponent] = useState<ConfigPropType>(initialConfigProp);
  const [showVersionCtrl, setShowVersionCtrl] = useState(true);
  const [canModify, setIfCanModify] = useState(true);

  useEffect(() => {
    if( VALIDATION_CONFIG_PROP_VERSION.includes(version) ) {
      setVersionComponent(version);
    } else {
      console.error(adrFile+"La version demandée n'est pas conforme !");
    }
  }, [version]);
  useEffect(() => {
    if( VALIDATION_CONFIG_PROP_TYPE.includes(type) ) {
      setTypeComponent(type);
    } else {
      console.error(adrFile+"Le type demandé n'est pas conforme !");
    }
  }, [type]);
  useEffect(() => {
    setConfigComponent(prevStat => ({
      ...prevStat,
      ...config,
    }));
  }, [config]);
  useEffect(() => {
    setIfCanModify(!isModifiable);
  }, [isModifiable]);

  const handelTypeChange = (newType: string) => {
    if( canModify ) {
      setTypeComponent(newType);
      onTypeCtrlChange(newType);

      if( newType !== CONFIG_PROP_TYPE_CTRL ) {
        setShowVersionCtrl(false);
        setVersionComponent(CONFIG_PROP_VERSION_COMPLET);
        onVersionCtrlChange(CONFIG_PROP_VERSION_COMPLET);
      } else {
        setShowVersionCtrl(true);
      }
    }
  };
  const handelVersionChange = (newVersion: string) => {
    if( canModify && ( typeComponent === CONFIG_PROP_TYPE_CTRL ) ) {
      setVersionComponent(newVersion);
      onVersionCtrlChange(newVersion);
    }
  };
  const handelConfigChange = (param: string, val: number) => {
    if( canModify ) {
      if( param === CONFIG_PROP_CONFIG_VISITE ) {
        setConfigComponent(prevStat => ({
          ...prevStat,
          visite: (val === 0) ? false : true,
        }));
      } else if( param === CONFIG_PROP_CONFIG_METEO ) {
        setConfigComponent(prevStat => ({
          ...prevStat,
          meteo: (val === 0) ? false : true,
        }));
      } else if( param === CONFIG_PROP_CONFIG_PRODUITS ) {
        setConfigComponent(prevStat => ({
          ...prevStat,
          produits: (val === 0) ? false : true,
        }));
      } else if( param === CONFIG_PROP_CONFIG_AFFICHAGE ) {
        setConfigComponent(prevStat => ({
          ...prevStat,
          affichage: (val === 0) ? false : true,
        }));
      }

      onConfigCtrlChange(configComponent);
    }
  }

  return (
    <div className="config-prop-component">
      <div className="row no-gutters" style={ { display: 'flex', alignItems: 'stretch' } }>
        <div className="config-prop-type col s12 m12 l8" style={  { padding: '0', marginLeft: '0' } }>
          <button
            className="btn btn-std"
            key={ CONFIG_PROP_TYPE_EDLE }
            onClick={ () => handelTypeChange(CONFIG_PROP_TYPE_EDLE) }
            style={ ( typeComponent === CONFIG_PROP_TYPE_EDLE ) ? { opacity: '1' } : { opacity: '0.5' } }
          >
            État des lieux entrant
          </button>
          <button
            className="btn btn-std"
            key={ CONFIG_PROP_TYPE_CTRL }
            onClick={ () => handelTypeChange(CONFIG_PROP_TYPE_CTRL) }
            style={ ( typeComponent === CONFIG_PROP_TYPE_CTRL ) ? { opacity: '1' } : { opacity: '0.5' } }
          >
            Contrôle
          </button>
          <button
            className="btn btn-std"
            key={ CONFIG_PROP_TYPE_LEVEE }
            onClick={ () => handelTypeChange(CONFIG_PROP_TYPE_LEVEE) }
            style={ ( typeComponent === CONFIG_PROP_TYPE_LEVEE ) ? { opacity: '1' } : { opacity: '0.5' } }
          >
            Levée de plan d'actions
          </button>
          <button
            className="btn btn-std"
            key={ CONFIG_PROP_TYPE_EDLS }
            onClick={ () => handelTypeChange(CONFIG_PROP_TYPE_EDLS) }
            style={ ( typeComponent === CONFIG_PROP_TYPE_EDLS ) ? { opacity: '1' } : { opacity: '0.5' } }
          >
            État des lieux sortant
          </button>
        </div>

        <div className="config-prop-version col s12 m12 l4" style={ ( showVersionCtrl ) ? { padding: '0', display: 'block' } : { display: 'none' } }>
          <button
            className="btn btn-std"
            key={ CONFIG_PROP_VERSION_COMPLET }
            onClick={ () => handelVersionChange(CONFIG_PROP_VERSION_COMPLET) }
            style={ ( versionComponent === CONFIG_PROP_VERSION_COMPLET ) ? { opacity: '1' } : { opacity: '0.5' } }
          >
            Complet
          </button>
          <button
            className="btn btn-std"
            key={ CONFIG_PROP_VERSION_ALEATOIRE }
            onClick={ () => handelVersionChange(CONFIG_PROP_VERSION_ALEATOIRE) }
            style={ ( versionComponent === CONFIG_PROP_VERSION_ALEATOIRE ) ? { opacity: '1' } : { opacity: '0.5' } }
          >
            Aléatoire
          </button>
          <button
            className="btn btn-std"
            key={ CONFIG_PROP_VERSION_SPECIFIQUE }
            onClick={ () => handelVersionChange(CONFIG_PROP_VERSION_SPECIFIQUE) }
            style={ ( versionComponent === CONFIG_PROP_VERSION_SPECIFIQUE ) ? { opacity: '1' } : { opacity: '0.5' } }
          >
            Spécifique
          </button>
        </div>
      </div>

      <div className="row" >
        <div className="config-prop-config col s12 m12 l12">
          <ToggleSwitchComponent
            param={CONFIG_PROP_CONFIG_VISITE}
            lbl0="Programmée"
            lbl1="Inopinée"
            value={configComponent.affichage}
            onChangeValue={(p, v) => handelConfigChange(p, v)}
          />
          <ToggleSwitchComponent
            param={CONFIG_PROP_CONFIG_METEO}
            lbl0="Normale"
            lbl1="Perturbée"
            value={configComponent.meteo}
            onChangeValue={(p, v) => handelConfigChange(p, v)}
          />
          <ToggleSwitchComponent
            param={CONFIG_PROP_CONFIG_PRODUITS}
            lbl0="Conforme"
            lbl1="Non conforme"
            value={configComponent.produits}
            onChangeValue={(p, v) => handelConfigChange(p, v)}
          />
          <ToggleSwitchComponent
            param={CONFIG_PROP_CONFIG_AFFICHAGE}
            lbl0="Conforme"
            lbl1="Non conforme"
            value={configComponent.affichage}
            onChangeValue={(p, v) => handelConfigChange(p, v)}
          />
        </div>
      </div>
    </div>
  )
}

export default ConfigPropComponent;
