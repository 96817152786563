
import React, { FunctionComponent, useState, useEffect } from "react";
import CryptoJS from 'crypto-js';
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";

import "./styles/login.css";
import logo from "../assets/logo_appli.png";

import { SECRET_KEY, URL_DGPF } from "../models/consts";
import { STORE_PARAM_MEMBRE, STORE_PARAM_CIBLE, STORE_PARAM_LIST_SELECT, STORE_PARAM_GRILLE_PROP, STORE_PARAM_CONF_PROP } from "../models/consts/store";
import { PARAM_LOGIN_TEST, PARAM_LOGIN_OK } from "../models/consts/login";
import { CONTROL_PROP_URL } from "../models/consts/aplliUrls";
import { MbrType, initialMbr } from "../models/mbr";
import { mapToGrillePropType } from "../models/ctrlProp";

import { useNavigation } from "../components/navigationContext";

import fetchLogin from "../services/login";

const Login: FunctionComponent = () => {
  const [form, setForm] = useState<MbrType>(initialMbr);
  const [message, setMessage] = useState<string>('👉 Entrez vos identifiants');
  const [rememberMe, setRememberMe] = useState<boolean>(false);
  const [dpgf, setDpgf] = useState<boolean>(true);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(false);

  const { setPreviousPath } = useNavigation();
  const history = useHistory();

  useEffect(() => {
    setPreviousPath(history.location.pathname);
  }, [setPreviousPath, history]);

  useEffect(() => {
    const fetchData = async () => {
      const encryptedDataFromStorage = localStorage.getItem('encryptedData');

      if (encryptedDataFromStorage !== null) {
        const decryptedBytes = CryptoJS.AES.decrypt(encryptedDataFromStorage, SECRET_KEY);
        const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
        const newForm = { ...form };

        const idMbr = (decryptedData.id === null || decryptedData.id === undefined) ? 0 : decryptedData.id;
        const pseudo = (decryptedData.pseudo === null || decryptedData.pseudo === undefined) ? '' : decryptedData.pseudo;
        const mdp = (decryptedData.mdp === null || decryptedData.mdp === undefined) ? '' : decryptedData.mdp;
        const mac = (decryptedData.mac === null || decryptedData.mac === undefined) ? '' : decryptedData.mac;

        newForm.id = idMbr;
        newForm.pseudo = pseudo;
        newForm.mdp = mdp;
        newForm.mac = mac;

        setRememberMe(true);
        setForm(newForm);

        try {
          const response = await fetchLogin(pseudo, mdp, mac, PARAM_LOGIN_TEST);

          if( response.result ) {
            const dataMbr = {
              id: response.donn.id,
              pseudo: response.donn.pseudo,
              mac: response.donn.mac,
              mdp: response.donn.mdp,
              idAgent: response.donn.idAgent,
              txt: response.donn.txt,
              mail: response.donn.mail,
              niveau: response.donn.niveau,
              tel: response.donn.tel,
              abonnements: response.donn.abonnements,
            };
            const dataAgcs = response.donn.agences;
            let dataCible = {
              agc: (dataMbr.niveau > 2) ? null : dataAgcs[0].id,
              grp: null,
              rsd: null,
              entry: null,
            };
            const dataProp = mapToGrillePropType(response.donn.grille.prop);
            const dataConf = response.donn.conf.prop;

            const encryptedDataCible = localStorage.getItem(STORE_PARAM_CIBLE);

            if( encryptedDataCible !== null ) {
              let decriptedDataCible = JSON.parse(CryptoJS.AES.decrypt(encryptedDataCible, SECRET_KEY).toString(CryptoJS.enc.Utf8));

              dataCible.agc = decriptedDataCible.agc;
              dataCible.grp = decriptedDataCible.grp;
              dataCible.rsd = decriptedDataCible.rsd;
              dataCible.entry = decriptedDataCible.entry;
            }

            setError(false);
            setMessage('👉 Connexion réussie !');

            const jsonMbr = JSON.stringify(dataMbr);
            const jsonAgcs = JSON.stringify(dataAgcs);
            const jsonCible = JSON.stringify(dataCible);
            const jsonProp = JSON.stringify(dataProp);
            const jsonConf = JSON.stringify(dataConf);

            let encryptedData = CryptoJS.AES.encrypt(jsonMbr, SECRET_KEY).toString();
            localStorage.setItem(STORE_PARAM_MEMBRE, encryptedData);

            encryptedData = CryptoJS.AES.encrypt(jsonAgcs, SECRET_KEY).toString();
            localStorage.setItem(STORE_PARAM_LIST_SELECT, encryptedData);

            encryptedData = CryptoJS.AES.encrypt(jsonCible, SECRET_KEY).toString();
            localStorage.setItem(STORE_PARAM_CIBLE, encryptedData);

            encryptedData = CryptoJS.AES.encrypt(jsonProp, SECRET_KEY).toString();
            localStorage.setItem(STORE_PARAM_GRILLE_PROP, encryptedData);

            encryptedData = CryptoJS.AES.encrypt(jsonConf, SECRET_KEY).toString();
            localStorage.setItem(STORE_PARAM_CONF_PROP, encryptedData);

            history.push(CONTROL_PROP_URL);
          }
        } catch (error) {
          setError(true);
          setMessage('👉 Echec de la connexion !');
        }
      } else {
        console.error('Erreur lors de la récupération des données depuis LocalStorage');
      }
    };

    fetchData();
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const newField = { ...form };

    if( e.target.name === 'pseudo' ) {
      newField.pseudo = e.target.value
    } else {
      newField.mdp = e.target.value
    }

    setForm(newField);
  };
  const handleRememberMeChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setRememberMe(e.target.checked);
  };
  const handleDpgfChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setDpgf(e.target.checked);
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    
    if( dpgf ) {
      setMessage('👉 Tentative de connexion en cours ...');

      if( rememberMe ) {
        const dataToStore = {
          id: form.id,
          pseudo: form.pseudo,
          mdp: form.mdp,
          mac: '',
        };

        const jsonData = JSON.stringify(dataToStore);
        const encryptedData = CryptoJS.AES.encrypt(jsonData, SECRET_KEY).toString();

        localStorage.setItem('encryptedData', encryptedData);
      }

      try {
        const response = await fetchLogin(form.pseudo, form.mdp, form.mac, PARAM_LOGIN_OK);

        if( response.result ) {
          const dataMbr = {
            id: response.donn.id,
            pseudo: response.donn.pseudo,
            mac: response.donn.mac,
            mdp: response.donn.mdp,
            idAgent: response.donn.idAgent,
            txt: response.donn.txt,
            mail: response.donn.mail,
            niveau: response.donn.niveau,
            tel: response.donn.tel,
            abonnements: response.donn.abonnements,
          };
          const dataAgcs = response.donn.agences;
          const dataCible = {
            agc: (dataMbr.niveau > 2) ? null : dataAgcs[0].id,
            grp: null,
            rsd: null,
            entry: null,
          };
          const dataProp = response.donn.grille.prop;
          const dataConf = response.donn.conf.prop;

          setError(false);
          setMessage('👉 Connexion réussie !');

          if( rememberMe ) {
            const dataToStore = {
              id: response.donn.id,
              pseudo: response.donn.pseudo,
              mdp: response.donn.mdp,
              mac: response.donn.mac,
            };
    
            const jsonData = JSON.stringify(dataToStore);
            const encryptedData = CryptoJS.AES.encrypt(jsonData, SECRET_KEY).toString();
    
            localStorage.setItem('encryptedData', encryptedData);
          }

          const jsonMbr = JSON.stringify(dataMbr);
          const jsonAgcs = JSON.stringify(dataAgcs);
          const jsonCible = JSON.stringify(dataCible);
          const jsonProp = JSON.stringify(dataProp);
          const jsonConf = JSON.stringify(dataConf);

          let encryptedData = CryptoJS.AES.encrypt(jsonMbr, SECRET_KEY).toString();
          localStorage.setItem(STORE_PARAM_MEMBRE, encryptedData);

          encryptedData = CryptoJS.AES.encrypt(jsonAgcs, SECRET_KEY).toString();
          localStorage.setItem(STORE_PARAM_LIST_SELECT, encryptedData);

          encryptedData = CryptoJS.AES.encrypt(jsonCible, SECRET_KEY).toString();
          localStorage.setItem(STORE_PARAM_CIBLE, encryptedData);

          encryptedData = CryptoJS.AES.encrypt(jsonProp, SECRET_KEY).toString();
          localStorage.setItem(STORE_PARAM_GRILLE_PROP, encryptedData);

          encryptedData = CryptoJS.AES.encrypt(jsonConf, SECRET_KEY).toString();
          localStorage.setItem(STORE_PARAM_CONF_PROP, encryptedData);

          history.push(CONTROL_PROP_URL);
        } else {
          setError(true);
          setMessage('👉 '+response.donn.txt);
        }
      } catch( error ) {
        setError(true);
        setMessage('👉 Une erreur s\'est produite lors de la connexion:');
      }
    } else {
      setMessage("🔐 Vous devez accepter les conditions de protection des données personnelles.")
    }
  };

  return (
    <div className="login-screen">
      <img src={logo} alt="logo" className="logo" />

      <div className="valign-wrapper">
        <div className="row">
          <div className="app-content col s12 m8 l8 offset-m2 offset-l2">
            <form onSubmit={ (e) => handleSubmit(e) } className="formulaire">
              <div className="card hoverable">
                <div className="card-stacked">
                  <div className="card-content">
                    <div className="form-group">
                      <div className="card-panel grey lighten-5" style={ (error) ? { color: "red" } : { color: "black" } }>
                        {message}
                      </div>
                    </div>
                
                    <div className="form-group">
                      <label htmlFor="pseudo">Identifiant</label>
                      <input 
                        id="pseudo" 
                        type="text" 
                        name="pseudo" 
                        className="form-control" 
                        value={ form.pseudo } 
                        onChange={ e => handleInputChange(e) }
                      />
                    </div>
                
                    <div className="form-group field-mdp">
                      <label htmlFor="mdp">Mot de passe</label>
                      { showPassword 
                        ? <FaEyeSlash 
                            className="fa-eye" 
                            onClick={ togglePasswordVisibility }
                          /> 
                        : <FaEye 
                            className="fa-eye" 
                            onClick={ togglePasswordVisibility }
                          />
                      }
                      <input 
                        id="mdp" 
                        type={ showPassword ? "text" : "password" } 
                        name="mdp" 
                        className="form-control" 
                        value={ form.mdp } 
                        onChange={ e => handleInputChange(e) } 
                        autoCapitalize="off"
                      />
                    </div>

                    <div className="form-group field-recup">
                      <Link to="/recup-mail">Récupérer vos identifiants</Link>
                    </div>

                    <div className="field-config">
                      <div className="form-group remember-me-view left-align">
                        <label htmlFor="rememberMe">
                          <input 
                            id="rememberMe" 
                            type="checkbox" 
                            name="rememberMe" 
                            checked={ rememberMe } 
                            onChange={ e => handleRememberMeChange(e) }
                          />
                          <span>Se souvenir de moi</span>
                        </label>
                      </div>

                      {/* Field dpgf */}
                      <div className="form-group dpgf-view left-align">
                        <label htmlFor="dpgf">
                          <input 
                            id="dpgf" 
                            type="checkbox" 
                            name="dpgf" 
                            checked={ dpgf } 
                            onChange={ e => handleDpgfChange(e) }
                          />
                          <span>
                            J'ai lu et j'accepte les <a href={`${URL_DGPF}`} target="_blank" rel="noopener noreferrer">conditions de protection des données personnelles</a>.
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* Submit button */}
                  <div className="card-action center">
                    <button type="submit" className="btn btn-std">SE CONNECTER</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
